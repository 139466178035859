import Vue from 'vue'
import Vuex from 'vuex'
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import createPersistedState from 'vuex-persistedstate'
import { pokemonTypeColors } from '@/utils/pokemonColors'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage // Use session storage instead of local storage
    })
  ],
  state: {
    user: null,
    showSessionWarning: false,
    favoriteType: null,
    appColors: {
      primary: '#cc0000',
      secondary: '#f0f0f0'
    }
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user
    },
    SET_SESSION_WARNING(state, show) {
      state.showSessionWarning = show
    },
    SET_FAVORITE_TYPE(state, type) {
      state.favoriteType = type
    },
    SET_APP_COLORS(state, colors) {
      state.appColors = colors
    }
  },
  actions: {
    async setUser({ commit, dispatch, state }, user) {
      const db = getFirestore();
      if (user) {
        // User is signing in
        const userRef = doc(db, 'users', user.uid);
        await setDoc(userRef, {
          online: true,
          lastActive: new Date()
        }, { merge: true });
        
        // Fetch full user data
        const userDoc = await getDoc(userRef);
        const userData = userDoc.data();
        commit('SET_USER', { ...user, ...userData });
    
        // Update favorite type and app colors
        if (userData.favoriteType) {
          dispatch('updateFavoriteType', userData.favoriteType);
        }
      } else {
        // User is signing out
        if (state.user) {
          const userRef = doc(db, 'users', state.user.uid);
          await setDoc(userRef, {
            online: false,
            lastActive: new Date()
          }, { merge: true });
        }
        commit('SET_USER', null);
        // Reset favorite type and app colors
        dispatch('updateFavoriteType', null);
      }
    },
    initializeAuth({ dispatch }) {
      const auth = getAuth();
      return new Promise((resolve) => {
        onAuthStateChanged(auth, async (user) => {
          if (user) {
            const db = getFirestore();
            const userDoc = await getDoc(doc(db, 'users', user.uid));
            const userData = userDoc.data();
            await dispatch('setUser', { ...user, ...userData });
          } else {
            await dispatch('setUser', null);
          }
          resolve();
        });
      });
    },
    updateFavoriteType({ commit }, type) {
      commit('SET_FAVORITE_TYPE', type)
      const colors = pokemonTypeColors[type] || { primary: '#cc0000', secondary: '#f0f0f0' }
      commit('SET_APP_COLORS', colors)
    }
  },
  getters: {
    isAdmin: state => state.user && state.user.isAdmin
  }
})