<template>
  <div class="profile-wrapper" :style="{ backgroundColor: $store.state.appColors.primary }">
    <button class="back-button" @click="goBack">←</button>
    <div class="profile-container">
      <div class="profile-header">
        <div class="avatar-container">
          <canvas ref="avatarCanvas" width="312" height="312"></canvas>
        </div>
        <div class="user-info">
          <div v-if="formattedUserNumber" class="user-number">#{{ formattedUserNumber }}</div>
          <h2 class="username">{{ user.username }}</h2>
          <p class="user-type">Type: {{ capitalizedFavoriteType }}</p>
        </div>
      </div>
      <div class="stats-container" :style="{ backgroundColor: $store.state.appColors.secondary }">
        <h3>Stats</h3>
        <div class="stat-item">
          <span>No. Cards:</span>
          <span>1st</span>
        </div>
        <div class="stat-item">
          <span>Highest Market Value:</span>
          <span>1st</span>
        </div>
        <div class="stat-item">
          <span>Favorite Pokémon:</span>
          <span>Gengar</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

export default {
  name: 'Profile',
  data() {
    return {
      userData: null,
    };
  },
  computed: {
    ...mapState(['user']),
    formattedUserNumber() {
      return this.userData && this.userData.userNumber 
        ? this.userData.userNumber.toString().padStart(3, '0') 
        : null;
    },
    capitalizedFavoriteType() {
      if (!this.user.favoriteType) return '';
      return this.user.favoriteType.charAt(0).toUpperCase() + this.user.favoriteType.slice(1);
    },
  },
  mounted() {
    this.fetchUserData();
    this.drawAvatar();
  },
  methods: {
    async fetchUserData() {
      if (this.user) {
        const db = getFirestore();
        const userDoc = await getDoc(doc(db, 'users', this.user.uid));
        if (userDoc.exists()) {
          this.userData = userDoc.data();
        }
      }
    },
    drawAvatar() {
      if (this.user && this.user.avatar) {
        const canvas = this.$refs.avatarCanvas;
        const ctx = canvas.getContext('2d');
        ctx.imageSmoothingEnabled = false;

        const drawFeature = (featureName, colorName) => {
          return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
              ctx.drawImage(img, 0, 0, 312, 312);
              resolve();
            };
            img.onerror = (error) => {
              console.error(`Failed to load image for ${featureName}:`, error);
              reject(error);
            };
            
            let imagePath;
            if (featureName === 'BaseSkin' || featureName === 'MouthsDefault') {
              imagePath = require(`@/assets/avatar/${featureName}/${this.user.avatar.features[featureName]}`);
            } else {
              imagePath = require(`@/assets/avatar/${featureName}/${colorName}/${this.user.avatar.features[featureName]}`);
            }
            img.src = imagePath;
          });
        };

        // Draw features in order
        Promise.all([
          drawFeature('BaseSkin'),
          drawFeature('Clothes', this.user.avatar.colors.Clothes),
          drawFeature('Hair', this.user.avatar.colors.Hair),
          drawFeature('Eyebrows', this.user.avatar.colors.Eyebrows),
          drawFeature('Eyes', this.user.avatar.colors.Eyes),
          drawFeature('MouthsDefault')
        ]).then(() => {
          console.log('Avatar drawn successfully');
        }).catch(error => {
          console.error('Error drawing avatar:', error);
        });
      }
    },
    goBack() {
      this.$emit('go-back');
    },
  },
};
</script>

<style scoped>
.profile-wrapper {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.profile-container {
  width: 100%;
  max-width: 600px;
  padding: 20px;
  color: white;
  display: flex;
  flex-direction: column;
}

.back-button {
  position: absolute;
  top: 20px;
  left: 20px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.profile-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.avatar-container {
  width: 156px;
  height: 156px;
  margin-bottom: 10px;
}

.avatar-container canvas {
  width: 100%;
  height: 100%;
}

.user-info {
  text-align: center;
}

.user-number {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 14px;
  margin-bottom: 5px;
  display: inline-block;
}

.username {
  font-size: 24px;
  margin: 5px 0;
}

.user-type {
  font-size: 16px;
  margin: 5px 0;
}

.stats-container {
  padding: 20px;
  border-radius: 10px;
  flex-grow: 1;
  color: white;
}

.stats-container h3 {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 18px;
}

.stat-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

/* Responsive styles */
@media (max-width: 480px) {
  .profile-wrapper {
    display: block;
  }

  .profile-container {
    padding: 10px;
    max-width: none;
  }

  .back-button {
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 10;
  }

  .avatar-container {
    width: 130px;
    height: 130px;
  }

  .username {
    font-size: 20px;
  }

  .user-type {
    font-size: 14px;
  }

  .stats-container {
    padding: 15px;
  }
}
</style>