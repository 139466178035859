import store from '../store';
import { getAuth } from 'firebase/auth';
import router from '../router';

const SESSION_DURATION = 20 * 60 * 1000; // 20 minutes
const WARNING_BEFORE_TIMEOUT = 2 * 60 * 1000; // Show warning 2 minutes before timeout

let timeoutId;
let warningTimeoutId;

export function setupSessionTimeout() {
  const auth = getAuth();
  if (auth.currentUser) {
    window.addEventListener('mousemove', resetSessionTimeout);
    window.addEventListener('keypress', resetSessionTimeout);
    window.addEventListener('touchstart', resetSessionTimeout);
    startSessionTimeout();
  }
}

export function startSessionTimeout() {
  clearTimeouts();
  setTimeouts();
}

function clearTimeouts() {
  clearTimeout(timeoutId);
  clearTimeout(warningTimeoutId);
}

function setTimeouts() {
  warningTimeoutId = setTimeout(showWarning, SESSION_DURATION - WARNING_BEFORE_TIMEOUT);
  timeoutId = setTimeout(logoutUser, SESSION_DURATION);
}

function resetSessionTimeout() {
  clearTimeouts();
  setTimeouts();
}

function showWarning() {
  store.commit('SET_SESSION_WARNING', true);
}

export function extendSession() {
  store.commit('SET_SESSION_WARNING', false);
  clearTimeouts();
  setTimeouts();
}

export async function logoutUser() {
  const auth = getAuth();
  if (auth.currentUser) {
    await auth.signOut();
    await store.dispatch('setUser', null);
    clearSessionTimeout();
    store.commit('SET_SESSION_WARNING', false);
    router.push('/login');
  }
}

export function clearSessionTimeout() {
  clearTimeouts();
  window.removeEventListener('mousemove', resetSessionTimeout);
  window.removeEventListener('keypress', resetSessionTimeout);
  window.removeEventListener('touchstart', resetSessionTimeout);
}