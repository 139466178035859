<template>
    <div class="battlefield">
      <div v-if="!selectedDeck" class="deck-selection">
        <h2>Select a Deck</h2>
        <div v-for="deck in decks" :key="deck.id" class="deck-option" @click="selectDeck(deck)">
          {{ deck.name }}
        </div>
      </div>
      <div v-else class="game-area">
        <div class="active-zone">
          <div class="active-slot" @dragover.prevent @drop="dropCard($event, 'active')">
            <card v-if="activeSlot" :card="activeSlot" />
          </div>
          <div class="item-slot" @dragover.prevent @drop="dropCard($event, 'item')">
            <card v-if="itemSlot" :card="itemSlot" />
          </div>
        </div>
        <div class="bench-zone">
          <div v-for="(slot, index) in benchSlots" :key="index" 
               class="bench-slot" @dragover.prevent @drop="dropCard($event, 'bench', index)">
            <card v-if="slot" :card="slot" />
          </div>
        </div>
        <div class="hand">
          <card v-for="(card, index) in hand" :key="index" 
                :card="card" :draggable="true" @dragstart="dragStart($event, index)" />
        </div>
        <button @click="drawCard" class="draw-button">Draw Card</button>
        <button @click="goBack" class="back-button">Back to Main Menu</button>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';
  import { getAuth } from 'firebase/auth';
  import { getFirestore, collection, getDocs } from 'firebase/firestore';
  import { getStorage, ref as storageRef, getDownloadURL } from 'firebase/storage';
  import Card from './Card.vue';
  
  export default {
    name: 'BattleField',
    components: {
      Card,
    },
    setup() {
      const auth = getAuth();
      const db = getFirestore();
      const storage = getStorage();
  
      const decks = ref([]);
      const selectedDeck = ref(null);
      const deck = ref([]);
      const hand = ref([]);
      const activeSlot = ref(null);
      const itemSlot = ref(null);
      const benchSlots = ref([null, null, null]);
  
      const fetchDecks = async () => {
        const userDecksRef = collection(db, 'users', auth.currentUser.uid, 'decks');
        const querySnapshot = await getDocs(userDecksRef);
        decks.value = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      };
  
      const selectDeck = async (selectedDeckData) => {
        selectedDeck.value = selectedDeckData;
        await initializeDeck();
        drawInitialHand();
      };
  
      const initializeDeck = async () => {
        deck.value = [];
        for (const card of selectedDeck.value.cards) {
          const cardData = { ...card };
          if (card.imageUrl) {
            try {
              const imageRef = storageRef(storage, card.imageUrl);
              cardData.imageUrl = await getDownloadURL(imageRef);
            } catch (error) {
              console.error("Error fetching image URL:", error);
              cardData.imageUrl = null;
            }
          }
          deck.value.push(cardData);
        }
        // Shuffle the deck
        deck.value = deck.value.sort(() => Math.random() - 0.5);
      };
  
      const drawInitialHand = () => {
        for (let i = 0; i < 5; i++) {
          drawCard();
        }
      };
  
      const drawCard = () => {
        if (deck.value.length > 0) {
          const card = deck.value.pop();
          hand.value.push(card);
        } else {
          alert('No more cards in the deck!');
        }
      };
  
      const dragStart = (event, index) => {
        event.dataTransfer.setData('text/plain', index);
      };
  
      const dropCard = (event, zone, benchIndex) => {
        const cardIndex = parseInt(event.dataTransfer.getData('text/plain'));
        const card = hand.value[cardIndex];
  
        if (zone === 'active' && card.cardType === 'Pokémon' && !activeSlot.value) {
          activeSlot.value = card;
          hand.value.splice(cardIndex, 1);
        } else if (zone === 'bench' && card.cardType === 'Pokémon' && !benchSlots.value[benchIndex]) {
          benchSlots.value[benchIndex] = card;
          hand.value.splice(cardIndex, 1);
        } else if (zone === 'item' && card.cardType !== 'Pokémon' && !itemSlot.value) {
          itemSlot.value = card;
          hand.value.splice(cardIndex, 1);
        } else {
          alert('Invalid move!');
        }
      };
  
      const goBack = () => {
        // Emit an event to go back to the main menu
      };
  
      onMounted(fetchDecks);
  
      return {
        decks,
        selectedDeck,
        hand,
        activeSlot,
        itemSlot,
        benchSlots,
        selectDeck,
        drawCard,
        dragStart,
        dropCard,
        goBack
      };
    }
  };
  </script>
  
  <style scoped>
  .battlefield {
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 20px;
    background-color: #e0f0e0;
  }
  
  .deck-selection {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .deck-option {
    margin: 10px;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .game-area {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .active-zone {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .active-slot, .item-slot {
    width: 120px;
    height: 180px;
    border: 2px dashed #333;
    margin: 0 10px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
  }
  
  .bench-zone {
    display: flex;
    justify-content: center;
  }
  
  .bench-slot {
    width: 100px;
    height: 150px;
    border: 2px dashed #666;
    margin: 0 10px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 10px;
  }
  
  .hand {
    display: flex;
    justify-content: center;
    padding: 20px;
    background-color: #d0e0d0;
    border-radius: 10px;
  }
  
  .hand > div {
    width: 80px;
    height: 120px;
    margin: 0 5px;
  }
  
  .draw-button, .back-button {
    margin-top: 10px;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .back-button {
    background-color: #f44336;
  }
  
  .draw-button:hover, .back-button:hover {
    opacity: 0.8;
  }
  </style>