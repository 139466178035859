<template>
    <div class="admin-panel">
      <header class="header">
        <button class="back-button" @click="goBack">←</button>
        <h1>ADMIN PANEL</h1>
        <div class="menu-button">⋮</div>
      </header>
      <div class="user-info">
        Logged in as {{ currentUser.username }} as {{ currentUser.isAdmin ? 'ADMIN' : 'USER' }}
      </div>
      <table class="user-table">
        <thead>
          <tr>
            <th>Online</th>
            <th>Username</th>
            <th>Role</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in users" :key="user.uid">
            <td>
              <span class="status-indicator" :class="{ 'online': user.online, 'offline': !user.online }"></span>
            </td>
            <td>{{ user.username }}</td>
            <td>{{ user.isAdmin ? 'ADMIN' : 'USER' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import { mapState, mapGetters } from 'vuex';
  import { getFirestore, collection, query, onSnapshot } from 'firebase/firestore';
  
  export default {
    name: 'AdminPanel',
    data() {
      return {
        users: []
      };
    },
    computed: {
      ...mapState(['user']),
      ...mapGetters(['isAdmin']),
      currentUser() {
        return this.user;
      }
    },
    methods: {
      goBack() {
        this.$emit('go-back');
      },
      async fetchUsers() {
        const db = getFirestore();
        const usersRef = collection(db, 'users');
        const q = query(usersRef);
  
        onSnapshot(q, (snapshot) => {
          this.users = snapshot.docs.map(doc => ({
            uid: doc.id,
            ...doc.data()
          }));
        });
      }
    },
    mounted() {
      if (!this.isAdmin) {
        this.$emit('go-back');
      } else {
        this.fetchUsers();
      }
    }
  };
  </script>
  
  <style scoped>
  .admin-panel {
    font-family: Arial, sans-serif;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .header {
    background-color: #ff3b30;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }
  
  .back-button, .menu-button {
    background: none;
    border: none;
    color: white;
    font-size: 1.5em;
    cursor: pointer;
  }
  
  h1 {
    margin: 0;
    font-size: 1.2em;
  }
  
  .user-info {
    background-color: #4a4a4a;
    color: white;
    padding: 10px;
    text-align: center;
  }
  
  .user-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .user-table th, .user-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .user-table th {
    background-color: #f2f2f2;
  }
  
  .status-indicator {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
  
  .online {
    background-color: green;
  }
  
  .offline {
    background-color: red;
  }
  </style>