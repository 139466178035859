<template>
    <div class="card-search">
      <div class="search-container">
        <input
          v-model="searchQuery"
          @input="debounceSearch"
          placeholder="Search for a card..."
          class="search-input"
        />
      </div>
      <div class="cache-progress">
        <div class="progress-bar">
          <div class="progress-fill" :style="{ width: `${cachingProgress}%` }"></div>
        </div>
        <p class="progress-text">Cache Progress: {{ cachingProgress }}%</p>
        <p class="total-cards">Total Cards Cached: {{ allCards.length }}</p>
      </div>
      <div v-if="loading" class="loading">Searching...</div>
      <div v-else>
        <div class="search-results">
          <div
            v-for="(result, index) in paginatedResults"
            :key="index"
            class="search-result"
            @click="selectCard(result)"
          >
            <div class="card">
              <div class="card-media">
                <img 
                  :src="result.imageUrl" 
                  :alt="getCardName(result)" 
                  class="card-image"
                  @error="handleImageError"
                  @load="handleImageLoad"
                >
                <img 
                  v-if="!result.imageLoaded"
                  src="@/assets/placeholder.webp" 
                  alt="Placeholder" 
                  class="card-image placeholder"
                >
              </div>
              <div class="card-content">
                <span class="card-title">{{ getCardName(result) }}</span>
                <div class="card-set-info">{{ result.setName }}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="searchResults.length > 0" class="pagination">
          <button @click="prevPage" :disabled="currentPage === 1" class="pagination-button">Previous</button>
          <span class="page-info">Page {{ currentPage }} of {{ totalPages }}</span>
          <button @click="nextPage" :disabled="currentPage === totalPages" class="pagination-button">Next</button>
        </div>
        <div v-else-if="searchQuery.length >= 3" class="no-results">No results found</div>
      </div>
    </div>
  </template>
  
  <script>
  import { getStorage, ref as storageRef, listAll, getDownloadURL } from 'firebase/storage';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import debounce from 'lodash/debounce';
import { openDB } from 'idb';

const DB_NAME = 'CardSearchDB';
const STORE_NAME = 'cards';
const METADATA_STORE = 'metadata';
const CACHE_VERSION = 5; // Increment this to trigger a cache rebuild
const CACHE_DURATION = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds

export default {
  name: 'CardSearch',
  data() {
    return {
      searchQuery: '',
      searchResults: [],
      loading: false,
      currentPage: 1,
      itemsPerPage: 11,
      allCards: [],
      cachingProgress: 0,
      db: null,
      totalExpectedCards: 17925, // Adjust this number if your total card count is different
    };
  },
  computed: {
    paginatedResults() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.searchResults.slice(start, end);
    },
    totalPages() {
      return Math.max(1, Math.ceil(this.searchResults.length / this.itemsPerPage));
    },
  },
  methods: {
    debounceSearch: debounce(function() {
      this.currentPage = 1;
      this.performSearch();
    }, 300),

    async performSearch() {
      console.log(`Performing search for: "${this.searchQuery}"`);
      if (this.searchQuery.length < 3) {
        this.searchResults = [];
        return;
      }

      this.loading = true;
      const lowerQuery = this.searchQuery.toLowerCase();
      
      this.searchResults = this.allCards.filter(card => {
        const nameMatch = card.name && card.name.toLowerCase().includes(lowerQuery);
        const setNameMatch = card.setName && card.setName.toLowerCase().includes(lowerQuery);
        const seriesMatch = card.series && card.series.toLowerCase().includes(lowerQuery);
        const cardNumberMatch = card.cardNumber && card.cardNumber.toString().includes(lowerQuery);
        
        console.log(`Card: ${card.name}, Set: ${card.setName}, Series: ${card.series}, Number: ${card.cardNumber}`);
        console.log(`Matches - Name: ${nameMatch}, Set: ${setNameMatch}, Series: ${seriesMatch}, Number: ${cardNumberMatch}`);
        
        return nameMatch || setNameMatch || seriesMatch || cardNumberMatch;
      });

      console.log(`Search completed. Found ${this.searchResults.length} results.`);
      this.loading = false;
    },

    async initIndexedDB() {
      try {
        this.db = await openDB(DB_NAME, CACHE_VERSION, {
          upgrade(db, oldVersion, newVersion, transaction) {
            console.log(`Upgrading IndexedDB from version ${oldVersion} to ${newVersion}`);
            
            if (!db.objectStoreNames.contains(STORE_NAME)) {
              db.createObjectStore(STORE_NAME, { keyPath: 'path' });
              console.log(`Created object store: ${STORE_NAME}`);
            } else {
              console.log(`Object store ${STORE_NAME} already exists`);
            }
            
            if (!db.objectStoreNames.contains(METADATA_STORE)) {
              db.createObjectStore(METADATA_STORE, { keyPath: 'key' });
              console.log(`Created object store: ${METADATA_STORE}`);
            } else {
              console.log(`Object store ${METADATA_STORE} already exists`);
            }

            if (oldVersion < 2) {
              const store = transaction.objectStore(STORE_NAME);
              if (!store.indexNames.contains('cardNumberFirst3')) {
                store.createIndex('cardNumberFirst3', 'cardNumberFirst3', { unique: false });
                console.log('Created index: cardNumberFirst3');
              }
            }
          },
          blocked() {
            console.warn('IndexedDB upgrade was blocked');
          },
          blocking() {
            console.warn('IndexedDB upgrade is blocking other connections');
          },
          terminated() {
            console.error('IndexedDB upgrade was terminated');
          },
        });
        console.log('IndexedDB initialized successfully');
      } catch (error) {
        console.error('Error initializing IndexedDB:', error);
      }
    },

    async loadCardsFromIndexedDB() {
      const tx = this.db.transaction(STORE_NAME, 'readonly');
      const store = tx.objectStore(STORE_NAME);
      this.allCards = await store.getAll();
      console.log(`Loaded ${this.allCards.length} cards from IndexedDB`);
      this.updateCachingProgress();
    },

    async shouldUpdateCache() {
      const tx = this.db.transaction(METADATA_STORE, 'readonly');
      const store = tx.objectStore(METADATA_STORE);
      const lastUpdate = await store.get('lastUpdate');
      const cachedVersion = await store.get('cacheVersion');

      console.log('Cached version:', cachedVersion?.value);
      console.log('Current version:', CACHE_VERSION);

      if (!cachedVersion || cachedVersion.value !== CACHE_VERSION) {
        console.log('Cache version mismatch. Updating cache.');
        return true;
      }

      return !lastUpdate || Date.now() - lastUpdate.value > CACHE_DURATION;
    },

    async updateCacheTimestamp() {
      const tx = this.db.transaction(METADATA_STORE, 'readwrite');
      const store = tx.objectStore(METADATA_STORE);
      await store.put({ key: 'lastUpdate', value: Date.now() });
      await store.put({ key: 'cacheVersion', value: CACHE_VERSION });
      console.log('Updated cache timestamp and version');
    },

    async cacheCards() {
      console.log('Starting to cache cards...');
      const storage = getStorage();
      const rootRef = storageRef(storage, 'pokemon_cards');

      // Clear existing data
      const tx = this.db.transaction(STORE_NAME, 'readwrite');
      const store = tx.objectStore(STORE_NAME);
      await store.clear();
      console.log('Cleared existing card data');

      this.allCards = []; // Reset allCards array
      this.cachingProgress = 0; // Reset progress

      await this.cacheCardsFromFolder(rootRef);
      await this.updateCacheTimestamp();
      this.updateCachingProgress();
      console.log('Finished caching all cards');
    },

    async cacheCardsFromFolder(folderRef) {
      try {
        const folderContent = await listAll(folderRef);
        const batchSize = 100;
        let batch = [];

        for (const item of folderContent.items) {
          batch.push(this.processCardItem(item));
          if (batch.length >= batchSize) {
            await Promise.all(batch);
            batch = [];
            this.updateCachingProgress();
          }
        }

        if (batch.length > 0) {
          await Promise.all(batch);
          this.updateCachingProgress();
        }

        for (const subfolder of folderContent.prefixes) {
          await this.cacheCardsFromFolder(subfolder);
        }
      } catch (error) {
        console.error('Error in cacheCardsFromFolder:', error);
      }
    },

    async processCardItem(item) {
      try {
        const decodedName = decodeURIComponent(item.name);
        const url = await getDownloadURL(item);
        const pathParts = item.fullPath.split('/');
        
        const fullCardNumber = decodedName.match(/(\d+)/)?.[0] || 'Unknown';
        const cardNumberFirst3 = fullCardNumber.padStart(3, '0').substring(0, 3);
        
        const card = {
          imageUrl: url,
          path: item.fullPath,
          series: pathParts[1] || 'Unknown Series',
          setName: pathParts[2] || 'Unknown Set',
          cardNumber: fullCardNumber,
          cardNumberFirst3: cardNumberFirst3,
          name: this.getCardName(item),
        };
        await this.db.put(STORE_NAME, card);
        this.allCards.push(card);
        this.updateCachingProgress();
      } catch (error) {
        console.error('Error processing card item:', error, item);
      }
    },

    updateCachingProgress() {
      this.cachingProgress = Math.min(100, Math.round((this.allCards.length / this.totalExpectedCards) * 100));
    },

    getCardName(item) {
      const fileName = item.name;
      const decodedFileName = decodeURIComponent(fileName);
      let cardName = decodedFileName.replace(/\.\w+$/, '').replace(/\d+.*$/, '');
      cardName = cardName.replace(/_/g, ' ');
      cardName = cardName.replace(/\s*-\s*$/, '');
      cardName = cardName.trim();
      return cardName;
    },

    async selectCard(card) {
      const plainCard = JSON.parse(JSON.stringify(card));
      console.log(`Card selected:`, plainCard);
      console.log(`Selected card path:`, plainCard.path);

      const pathParts = plainCard.path.split('/');
      const series = pathParts[1];
      const setName = pathParts[2];
      const fileName = pathParts[3];

      const cardNumberMatch = fileName.match(/(\d{3})/);
      const cardNumberFirst3 = cardNumberMatch ? cardNumberMatch[1] : null;

      if (!series || !setName || !cardNumberFirst3) {
        console.error('Invalid card data:', plainCard);
        return;
      }
      try {
        const db = getFirestore();
        const cardDocRef = doc(db, 'pokemon_cards', series, setName, cardNumberFirst3);

        console.log('Querying Firestore path:', cardDocRef.path);

        const cardDoc = await getDoc(cardDocRef);
        if (cardDoc.exists()) {
          const cardData = cardDoc.data();
          const cardWithCorrectName = {
            ...plainCard,
            name: cardData.name,
            ...cardData,
            imageUrl: plainCard.imageUrl,
            path: plainCard.path,
            setBox: `${setName} ${cardData.card_number || ''}`.trim(),
            cardType: cardData.type || '',
            rarity: cardData.rarity || '',
            energyType: cardData.energy_type || '',
            printRun: 'Unlimited',
            condition: 'Near Mint',
            language: 'English',
            foilPattern: 'None'
          };
          this.$emit('card-selected', cardWithCorrectName);
          console.log('Card data found and emitted:', cardWithCorrectName);
        } else {
          console.log('Card data not found in Firestore');
          const defaultCard = {
            ...plainCard,
            setBox: `${setName} ${cardNumberFirst3 || ''}`.trim(),
            printRun: 'Unlimited',
            condition: 'Near Mint',
            language: 'English',
            foilPattern: 'None'
          };
          this.$emit('card-selected', defaultCard);
        }
      } catch (error) {
        console.error('Error fetching card data:', error);
        this.$emit('card-selected', plainCard);
      }
    },

    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },

    handleImageError(event) {
      event.target.src = require('@/assets/placeholder.webp');
    },

    handleImageLoad(event) {
      const result = this.paginatedResults.find(r => r.imageUrl === event.target.src);
      if (result) {
        this.$set(result, 'imageLoaded', true);
      }
    }
  },
  async mounted() {
    try {
      await this.initIndexedDB();
      if (this.db) {
        if (await this.shouldUpdateCache()) {
          console.log('Cache needs updating. Rebuilding...');
          await this.cacheCards();
        } else {
          console.log('Using existing cache');
          await this.loadCardsFromIndexedDB();
          this.updateCachingProgress();
        }
      } else {
        console.error('IndexedDB initialization failed');
      }
    } catch (error) {
      console.error('Error in mounted hook:', error);
    }
  }
};
  </script>
  
  <style scoped>
  .card-search {
    margin-bottom: 20px;
  }
  
  .search-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .search-input {
    width: 100%;
    max-width: 500px;
    padding: 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .cache-progress {
    margin-top: 10px;
    text-align: center;
  }
  
  .progress-bar {
    width: 100%;
    height: 20px;
    background-color: #e0e0e0;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .progress-fill {
    height: 100%;
    background-color: #4CAF50;
    transition: width 0.3s ease;
  }
  
  .progress-text, .total-cards {
    margin: 5px 0;
    font-size: 14px;
    color: #666;
  }
  
  .search-results {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    justify-content: center;
  }
  
  .search-result {
  cursor: pointer;
}

.card {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  background-color: #fff;
  transition: transform 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
}

.card-media {
  position: relative;
  padding-top: 140%; /* 7:5 aspect ratio */
}

.card-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.card-image.placeholder {
  object-fit: contain;
  background-color: #f0f0f0;
}

.card-content {
  padding: 12px;
}

.card-title {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 4px;
  display: block;
}

.card-set-info {
  font-size: 12px;
  color: #666;
}

.loading, .no-results {
  text-align: center;
  font-style: italic;
  margin: 20px 0;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-top: 20px;
}

.pagination-button {
  padding: 10px 15px;
  cursor: pointer;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
}

.pagination-button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.page-info {
  font-size: 14px;
}

/* Responsive styles */
@media (max-width: 767px) {
  .search-input {
    font-size: 16px;
    padding: 15px;
  }

  .search-results {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 15px;
  }

  .card-title {
    font-size: 12px;
  }

  .card-set-info {
    font-size: 10px;
  }

  .pagination-button {
    padding: 12px;
    font-size: 14px;
  }

  .progress-text, .total-cards {
    font-size: 12px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .search-results {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .search-results {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}
</style>