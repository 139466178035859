<template>
  <div class="mobile-deck-view">
    <div class="deck-header">
      <button @click="goBack" class="back-button">←</button>
      <h1 class="deck-name">{{ deckName }}</h1>
      <button @click="showDeckMenu" class="menu-button">⋮</button>
    </div>
    <div class="deck-stats">
      <div class="stat">POKÉMON<br>{{ pokemonCount }}</div>
      <div class="stat">TRAINERS<br>{{ trainerCount }}</div>
      <div class="stat">ENERGY<br>{{ energyCount }}</div>
      <div class="stat">INVENTORY<br>{{ totalCards }}/60</div>
    </div>
    
    <div class="card-grid">
      <div v-for="(card, index) in cards" :key="index" class="card-item" @click="editCard(index)">
        <img :src="card.imageUrl" :alt="card.name" class="card-image">
        <div class="card-quantity">{{ card.quantity }}</div>
      </div>
    </div>

    <div class="card-library-search" :class="{ 'expanded': isSearchExpanded }">
      <div class="search-handle" @click="toggleSearch">
        <span v-if="isSearchExpanded">Close</span>
        <span v-else>Search</span>
      </div>
      <div class="search-content">
        <CardSearch @card-selected="handleCardSelected" />
      </div>
    </div>

    <button v-if="!isSearchExpanded" @click="expandSearch" class="search-fab">
      <font-awesome-icon :icon="['fas', 'search']" />
    </button>

    <div v-if="showCardForm" class="card-form-modal">
      <div class="modal-content">
        <button @click="closeCardForm" class="close-modal-button">&times;</button>
        <CardForm 
          :card="selectedCard" 
          :editing-index="editingIndex" 
          :total-cards="totalCards"
          @add-card="addCard" 
          @update-card="updateCard" 
        />
        <div class="card-action-buttons">
          <button v-if="editingIndex !== null" @click="removeCard" class="remove-card-button">Remove Card</button>
        </div>
      </div>
    </div>

    <div v-if="showingDeckMenu" class="deck-menu-modal">
      <div class="modal-content">
        <h2>Inventory Menu</h2>
        <input v-model="deckName" placeholder="Deck Name" class="deck-name-input">
        <div class="image-selection">
          <h3>Select Inventory Image</h3>
          <div class="image-grid">
            <img 
              v-for="card in cards" 
              :key="card.id" 
              :src="card.imageUrl" 
              :class="{ selected: selectedImageUrl === card.imageUrl }"
              @click="selectedImageUrl = card.imageUrl"
            >
          </div>
        </div>
        <button @click="saveDeck" class="save-button">Save Inventory</button>
        <button @click="closeDeckMenu" class="cancel-button">Cancel</button>
        <button v-if="deck.id" @click="deleteDeck" class="delete-button">Delete Inventory</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue';
import { getAuth } from 'firebase/auth';
import { db } from '../firebase';
import { doc, setDoc, addDoc, collection, serverTimestamp, getDocs, query, where, deleteDoc } from 'firebase/firestore';
import CardForm from './CardForm.vue';
import CardSearch from './CardSearch2.vue';

export default {
  name: 'MobileDeckView',
  components: {
    CardForm,
    CardSearch
  },
  props: {
    deck: {
      type: Object,
      required: true
    }
  },
  emits: ['save-deck', 'delete-deck', 'cancel', 'go-back'],
  setup(props, { emit }) {
    const auth = getAuth();

    const deckName = ref(props.deck.name);
    const cards = ref(props.deck.cards || []);
    const selectedCard = ref(null);
    const showingDeckMenu = ref(false);
    const isSearchExpanded = ref(false);
    const showCardForm = ref(false);
    const editingIndex = ref(null);
    const loading = ref(false);
    const error = ref(null);
    const selectedImageUrl = ref(props.deck.imageUrl || '');

    const totalCards = computed(() => {
      return cards.value.reduce((sum, card) => sum + (parseInt(card.quantity) || 0), 0);
    });

    const removeCard = () => {
      if (editingIndex.value !== null) {
        cards.value.splice(editingIndex.value, 1);
        closeCardForm();
      }
    };

    const pokemonCount = computed(() => {
      return cards.value
        .filter(card => card.cardType === 'Pokémon')
        .reduce((sum, card) => sum + (parseInt(card.quantity) || 0), 0);
    });

    const trainerCount = computed(() => {
      return cards.value
        .filter(card => card.cardType === 'Trainer')
        .reduce((sum, card) => sum + (parseInt(card.quantity) || 0), 0);
    });

    const energyCount = computed(() => {
      return cards.value
        .filter(card => card.cardType === 'Energy')
        .reduce((sum, card) => sum + (parseInt(card.quantity) || 0), 0);
    });

    const isValid = computed(() => totalCards.value === 60);

    const deckValue = computed(() => {
      return cards.value.reduce((total, card) => {
        return total + (parseFloat(card.marketValue) || 0) * card.quantity;
      }, 0);
    });

    const showDeckMenu = () => {
      showingDeckMenu.value = true;
    };

    const closeDeckMenu = () => {
      showingDeckMenu.value = false;
    };

    const toggleSearch = () => {
      isSearchExpanded.value = !isSearchExpanded.value;
    };

    const expandSearch = () => {
      isSearchExpanded.value = true;
    };

    const handleCardSelected = (card) => {
      selectedCard.value = { ...card };
      showCardForm.value = true;
      editingIndex.value = null;
    };

    const addCard = (card) => {
      cards.value.push(card);
      showCardForm.value = false;
    };

    const updateCard = ({ index, card }) => {
      if (index !== undefined && index !== null) {
        cards.value[index] = { ...cards.value[index], ...card };
        // Force reactivity update
        cards.value = [...cards.value];
      }
      showCardForm.value = false;
      editingIndex.value = null;
    };

    const editCard = (index) => {
      selectedCard.value = { ...cards.value[index] };
      editingIndex.value = index;
      showCardForm.value = true;
    };

    const closeCardForm = () => {
      showCardForm.value = false;
      selectedCard.value = null;
      editingIndex.value = null;
    };

    const saveDeck = async () => {
      loading.value = true;
      error.value = null;
      try {
        const userDecksRef = collection(db, 'users', auth.currentUser.uid, 'decks');
        
        const existingDeckQuery = query(userDecksRef, where('name', '==', deckName.value));
        const existingDeckSnapshot = await getDocs(existingDeckQuery);
        
        const deckData = {
          name: deckName.value,
          cards: cards.value,
          lastModified: serverTimestamp(),
          isValid: isValid.value,
          imageUrl: selectedImageUrl.value || require('@/assets/placeholder.webp'),
          deckValue: deckValue.value
        };

        if (!existingDeckSnapshot.empty && !props.deck.id) {
          error.value = "A deck with this name already exists. Please choose a different name.";
          return;
        }
        
        if (props.deck.id) {
          await setDoc(doc(userDecksRef, props.deck.id), deckData, { merge: true });
          deckData.id = props.deck.id;
        } else {
          const docRef = await addDoc(userDecksRef, deckData);
          deckData.id = docRef.id;
        }
        closeDeckMenu();
        emit('save-deck', deckData);
      } catch (err) {
        console.error("Error saving deck:", err);
        error.value = "Failed to save deck. Please try again.";
      } finally {
        loading.value = false;
      }
    };

    const deleteDeck = async () => {
      if (!props.deck.id) return;

      if (confirm('Are you sure you want to delete this deck?')) {
        try {
          await deleteDoc(doc(db, 'users', auth.currentUser.uid, 'decks', props.deck.id));
          closeDeckMenu();
          emit('delete-deck', props.deck.id);
        } catch (err) {
          console.error("Error deleting deck:", err);
          error.value = "Failed to delete deck. Please try again.";
        }
      }
    };

    const goBack = () => {
      emit('go-back');
    };

    watch(() => props.deck, (newDeck) => {
      deckName.value = newDeck.name;
      cards.value = newDeck.cards || [];
    }, { deep: true });

    return {
      deckName,
      cards,
      selectedCard,
      showingDeckMenu,
      isSearchExpanded,
      showCardForm,
      editingIndex,
      loading,
      error,
      selectedImageUrl,
      pokemonCount,
      trainerCount,
      energyCount,
      totalCards,
      isValid,
      deckValue,
      showDeckMenu,
      closeDeckMenu,
      toggleSearch,
      expandSearch,
      handleCardSelected,
      addCard,
      updateCard,
      editCard,
      removeCard,
      closeCardForm,
      saveDeck,
      deleteDeck,
      goBack
    };
  }
};
</script>

<style scoped>
.mobile-deck-view {
  font-family: Arial, sans-serif;
  max-width: 100%;
  margin: 0 auto;
  background-color: #f0f0f0;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.deck-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #cc0000;
  color: white;
  height: 50px;
}

.back-button, .menu-button {
  background: none;
  border: none;
  color: white;
  font-size: 1.5em;
  cursor: pointer;
}

.back-button {
  padding-left: 15px;
}

.menu-button{
  padding-right: 15px;
}

.deck-name {
  margin: 0;
  font-size: 1.5em;
  font-weight: bold;
}

.deck-stats {
  display: flex;
  justify-content: space-around;
  background-color: #333;
  color: #ffcb05;
  padding: 10px 0;
}

.stat {
  text-align: center;
  font-size: 0.9em;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  gap: 10px;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  margin-top: 10px;
  overflow-y: auto;
  flex-grow: 1;
  height: calc(100vh - 200px);
  align-content: start;
}

.card-item {
  width: 100%;
  padding-top: 140%;
  position: relative;
  cursor: pointer;
}

.card-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.card-quantity {
  position: absolute;
  bottom: 5px;
  right: 5px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 12px;
}

.card-library-search {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-out;
  transform: translateY(calc(100% - 40px));
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.card-library-search.expanded {
  transform: translateY(0);
}

.search-handle {
  height: 40px;
  background: #f0f0f0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.search-content {
  padding: 20px;
  max-height: 80vh;
  overflow-y: auto;
}

.search-fab {
  position: fixed;
  bottom: 80px;
  right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #4CAF50;
  color: white;
  border: none;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;
}

.search-fab:hover {
  background-color: #45a049;
}

.card-form-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 600px;
  max-height: 1200px;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-card-image {
  width: 200px;
  height: auto;
  margin-bottom: 20px;
}

.close-modal-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
}

.update-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
}

.deck-menu-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.deck-name-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.image-selection {
  margin-top: 20px;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
  gap: 10px;
  max-height: 200px;
  overflow-y: auto;
}

.image-grid img {
  width: 100%;
  height: auto;
  object-fit: contain;
  cursor: pointer;
  border: 2px solid transparent;
}

.image-grid img.selected {
  border-color: #4CAF50;
}

.save-button,
.cancel-button,
.delete-button {
  padding: 10px 20px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  color: white;
  width: 100%;
}

.save-button {
  background-color: #4CAF50;
}

.cancel-button {
  background-color: #f44336;
}

.delete-button {
  background-color: #ff9800;
}

.card-action-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  max-width: 120px;
  font-size: 12px;
}

.remove-card-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  max-width: 120px;
  width: 100%;
}

@media (max-width: 767px) {
  .deck-name {
    font-size: 1.2em;
  }

  .stat {
    font-size: 0.8em;
  }

  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 600px;
    max-height: 1100px;
    overflow-y: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .modal-card-image {
    width: 100px;
    height: auto;
  }

  .save-button,
  .cancel-button,
  .delete-button,
  .update-button {
    padding: 8px 15px;
    font-size: 14px;
  }

  .remove-card-button {
    padding: 8px 15px;
    font-size: 10px;
    max-width: 120px;
    width: 100%;
  }

  .image-grid {
    grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
  }
}

@media (min-width: 768px) {
  .card-grid {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .deck-name {
    font-size: 1.3em;
  }

  .stat {
    font-size: 0.85em;
  }

  .modal-content {
    width: 70%;
  }
}

@media (min-width: 1024px) {
  .mobile-deck-view {
    max-width: 1200px;
    margin: 0 auto;
  }

  .deck-name {
    font-size: 1.5em;
  }

  .stat {
    font-size: 1em;
  }

  .modal-content {
    width: 50%;
  }

  .card-grid {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }
}
</style>