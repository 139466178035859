<template>
  <div class="forum-page">
    <div class="header">
      <button @click="$emit('go-back')" class="back-button">←</button>
      <h1>Forum</h1>
    </div>

    <div class="posts-container">
      <div v-for="post in posts" :key="post.id" class="post-card" @click="showPostDetails(post)">
        <h2>{{ post.title }}</h2>
        <p class="post-content">{{ post.content }}</p>
        <div class="post-meta">
          <span>{{ post.authorName }} </span>
          <span>{{ formatDate(post.createdAt) }}</span>
        </div>
        <div class="post-actions">
          <button @click.stop="toggleFavorite(post)" :class="{ 'favorited': post.isFavorited }">
            ★ {{ post.favorites }}
          </button>
          <button @click.stop="showComments(post)">
            💬 {{ post.comments ? post.comments.length : 0 }}
          </button>
          <button v-if="post.authorId === user.uid" @click.stop="editPost(post)">Edit</button>
          <button v-if="post.authorId === user.uid" @click.stop="deletePost(post)" class="delete-button">
            🗑️ Delete
          </button>
        </div>
      </div>
    </div>

    <div class="card-library-search" :class="{ 'expanded': isSearchExpanded }">
      <div class="search-handle" @click="toggleSearch">
        <span v-if="isSearchExpanded">Close</span>
        <span v-else>New Post</span>
      </div>
      <div class="search-content">
        <div class="new-post-form">
          <input v-model="newPostTitle" placeholder="Post title" />
          <textarea v-model="newPostContent" placeholder="What's on your mind?"></textarea>
          <button @click="submitPost">{{ editingPost ? 'Update' : 'Post' }}</button>
          <button @click="cancelPost">Cancel</button>
        </div>
      </div>
    </div>

    <div v-if="selectedPost" class="post-details-modal">
      <div class="modal-content">
        <button @click="closePostDetails" class="close-modal-button">&times;</button>
        <h2>{{ selectedPost.title }}</h2>
        <p>{{ selectedPost.content }}</p>
        <div class="post-meta">
          <span>{{ selectedPost.authorName }} </span>
          <span>{{ formatDate(selectedPost.createdAt) }}</span>
        </div>
        <div class="post-actions">
          <button @click="toggleFavorite(selectedPost)" :class="{ 'favorited': selectedPost.isFavorited }">
            ★ {{ selectedPost.favorites }}
          </button>
          <button v-if="selectedPost.authorId === user.uid" @click="editPost(selectedPost)">Edit</button>
          <button v-if="selectedPost.authorId === user.uid" @click="deletePost(selectedPost)" class="delete-button">
            Delete
          </button>
        </div>
        <div class="comments-section">
          <div v-for="comment in selectedPost.comments" :key="comment.id" class="comment-container">
            <div class="comment">
              <p>{{ comment.content }}</p>
              <div class="comment-meta">
                <span>{{ comment.authorName }} • {{ formatDate(comment.createdAt) }}</span>
                <div class="comment-actions">
                  <button @click.stop="toggleCommentFavorite(selectedPost, comment)" :class="{ 'favorited': comment.isFavorited }">
                    ★ {{ comment.favorites }}
                  </button>
                  <button v-if="comment.authorId === user.uid" @click.stop="editComment(comment)">Edit</button>
                  <button v-if="comment.authorId === user.uid" @click.stop="deleteComment(selectedPost, comment)" class="delete-button">
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="comment-form">
            <textarea v-model="newComment" :placeholder="editingComment ? 'Edit comment' : (replyingToComment ? 'Reply to comment' : 'Add a comment')"></textarea>
            <button @click="addComment(selectedPost)">{{ editingComment ? 'Update' : (replyingToComment ? 'Reply' : 'Comment') }}</button>
            <button v-if="editingComment || replyingToComment" @click="cancelEditOrReply">Cancel</button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="selectedComment" class="comment-modal">
      <div class="modal-content">
        <button @click="closeCommentModal" class="close-modal-button">&times;</button>
        <p>{{ selectedComment.content }}</p>
        <div class="comment-meta">
          <span>{{ selectedComment.authorName }}</span>
          <span>{{ formatDate(selectedComment.createdAt) }}</span>
        </div>
        <div class="comment-actions">
          <button v-if="selectedComment.authorId === user.uid" @click="editComment(selectedComment)">Edit</button>
          <button @click="replyToComment">Reply</button>
          <button v-if="selectedComment.authorId === user.uid" @click="deleteComment(selectedPost, selectedComment)" class="delete-button">
            Delete
          </button>
        </div>
      </div>
    </div>

    <button v-if="!isSearchExpanded" @click="expandSearch" class="new-post-fab">
      <span class="fab-icon">+</span>
    </button>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { db } from '../firebase';
import { 
  collection, addDoc, getDocs, updateDoc, doc, deleteDoc,
  query, orderBy, limit, startAfter, serverTimestamp 
} from 'firebase/firestore';

export default {
  name: 'ForumPage',
  data() {
    return {
      posts: [],
      newPostTitle: '',
      newPostContent: '',
      isSearchExpanded: false,
      editingPost: null,
      lastVisible: null,
      loading: false,
      selectedPost: null,
      selectedComment: null,
      newComment: '',
      replyingToComment: null,
      editingComment: null
    };
  },
  computed: {
    ...mapState(['user'])
  },
  methods: {
    async loadPosts() {
      if (this.loading) return;
      this.loading = true;
      try {
        const postsRef = collection(db, 'forum_posts');
        let postsQuery;
        if (this.lastVisible) {
          postsQuery = query(postsRef, orderBy('createdAt', 'desc'), startAfter(this.lastVisible), limit(10));
        } else {
          postsQuery = query(postsRef, orderBy('createdAt', 'desc'), limit(10));
        }
        const snapshot = await getDocs(postsQuery);
        const newPosts = await Promise.all(snapshot.docs.map(async (doc) => {
          const data = doc.data();
          const commentsRef = collection(doc.ref, 'comments');
          const commentsSnapshot = await getDocs(query(commentsRef, orderBy('createdAt', 'desc')));
          const comments = commentsSnapshot.docs.map(commentDoc => ({
            id: commentDoc.id,
            ...commentDoc.data(),
            createdAt: commentDoc.data().createdAt ? commentDoc.data().createdAt.toDate() : null,
            isFavorited: commentDoc.data().favoritedBy?.includes(this.user.uid)
          }));
          return {
            id: doc.id,
            ...data,
            createdAt: data.createdAt ? data.createdAt.toDate() : null,
            isFavorited: data.favoritedBy?.includes(this.user.uid),
            comments: comments
          };
        }));
        this.posts = [...this.posts, ...newPosts];
        this.lastVisible = snapshot.docs[snapshot.docs.length - 1];
      } catch (error) {
        console.error('Error loading posts:', error);
      } finally {
        this.loading = false;
      }
    },
    async submitPost() {
      if (!this.newPostTitle || !this.newPostContent) return;
      try {
        const postData = {
          title: this.newPostTitle,
          content: this.newPostContent,
          authorId: this.user.uid,
          authorName: this.user.username,
          createdAt: serverTimestamp(),
          favorites: 0,
          favoritedBy: []
        };
        
        const localPostData = {
          ...postData,
          createdAt: new Date()
        };
        
        if (this.editingPost) {
          await updateDoc(doc(db, 'forum_posts', this.editingPost.id), postData);
          const index = this.posts.findIndex(p => p.id === this.editingPost.id);
          if (index !== -1) {
            this.$set(this.posts, index, { ...this.posts[index], ...localPostData });
          }
        } else {
          const docRef = await addDoc(collection(db, 'forum_posts'), postData);
          this.posts.unshift({ id: docRef.id, ...localPostData });
        }
        this.newPostTitle = '';
        this.newPostContent = '';
        this.isSearchExpanded = false;
        this.editingPost = null;
      } catch (error) {
        console.error('Error submitting post:', error);
      }
    },
    cancelPost() {
      this.newPostTitle = '';
      this.newPostContent = '';
      this.isSearchExpanded = false;
      this.editingPost = null;
    },
    editPost(post) {
      this.editingPost = post;
      this.newPostTitle = post.title;
      this.newPostContent = post.content;
      this.isSearchExpanded = true;
      this.closePostDetails();
    },
    async deletePost(post) {
      if (confirm('Are you sure you want to delete this post?')) {
        try {
          await deleteDoc(doc(db, 'forum_posts', post.id));
          this.posts = this.posts.filter(p => p.id !== post.id);
          if (this.selectedPost && this.selectedPost.id === post.id) {
            this.closePostDetails();
          }
        } catch (error) {
          console.error('Error deleting post:', error);
        }
      }
    },
    showPostDetails(post) {
      this.selectedPost = post;
    },
    closePostDetails() {
      this.selectedPost = null;
      this.newComment = '';
      this.replyingToComment = null;
      this.editingComment = null;
    },
    async addComment(post) {
      if (!this.newComment) return;
      try {
        const comment = {
          content: this.newComment,
          authorId: this.user.uid,
          authorName: this.user.username,
          createdAt: this.editingComment ? this.editingComment.createdAt : serverTimestamp(),
          editedAt: this.editingComment ? serverTimestamp() : null,
          favorites: this.editingComment ? this.editingComment.favorites : 0,
          favoritedBy: this.editingComment ? this.editingComment.favoritedBy : [],
          parentCommentId: this.replyingToComment ? this.replyingToComment.id : null
        };
        
        const postRef = doc(db, 'forum_posts', post.id);
        const commentsRef = collection(postRef, 'comments');

        if (this.editingComment) {
          // Update existing comment
          await updateDoc(doc(commentsRef, this.editingComment.id), comment);
          const commentIndex = post.comments.findIndex(c => c.id === this.editingComment.id);
          if (commentIndex !== -1) {
            this.$set(post.comments, commentIndex, {
              ...this.editingComment,
              ...comment,
              id: this.editingComment.id,
              editedAt: new Date()
            });
          }
        } else {
          // Add new comment
          const newCommentRef = await addDoc(commentsRef, comment);
          const newComment = {
            id: newCommentRef.id,
            ...comment,
            createdAt: new Date()
          };
          post.comments.push(newComment);
        }

        this.newComment = '';
        this.editingComment = null;
        this.replyingToComment = null;
      } catch (error) {
        console.error('Error adding/updating comment:', error);
      }
    },
    async deleteComment(post, comment) {
      if (confirm('Are you sure you want to delete this comment?')) {
        try {
          const postRef = doc(db, 'forum_posts', post.id);
          const commentRef = doc(postRef, 'comments', comment.id);
          await deleteDoc(commentRef);
          post.comments = post.comments.filter(c => c.id !== comment.id);
          if (this.selectedComment && this.selectedComment.id === comment.id) {
            this.closeCommentModal();
          }
        } catch (error) {
          console.error('Error deleting comment:', error);
        }
      }
    },
    async toggleFavorite(post) {
      try {
        const postRef = doc(db, 'forum_posts', post.id);
        const isFavorited = post.favoritedBy?.includes(this.user.uid);
        const newFavoritedBy = isFavorited
          ? post.favoritedBy.filter(id => id !== this.user.uid)
          : [...(post.favoritedBy || []), this.user.uid];
        await updateDoc(postRef, {
          favorites: newFavoritedBy.length,
          favoritedBy: newFavoritedBy
        });
        this.$set(post, 'favorites', newFavoritedBy.length);
        this.$set(post, 'favoritedBy', newFavoritedBy);
        this.$set(post, 'isFavorited', !isFavorited);
      } catch (error) {
        console.error('Error toggling favorite:', error);
      }
    },
    async toggleCommentFavorite(post, comment) {
      try {
        const postRef = doc(db, 'forum_posts', post.id);
        const commentRef = doc(postRef, 'comments', comment.id);
        const isFavorited = comment.favoritedBy?.includes(this.user.uid);
        const newFavoritedBy = isFavorited
          ? comment.favoritedBy.filter(id => id !== this.user.uid)
          : [...(comment.favoritedBy || []), this.user.uid];
        await updateDoc(commentRef, {
          favorites: newFavoritedBy.length,
          favoritedBy: newFavoritedBy
        });
        this.$set(comment, 'favorites', newFavoritedBy.length);
        this.$set(comment, 'favoritedBy', newFavoritedBy);
        this.$set(comment, 'isFavorited', !isFavorited);
      } catch (error) {
        console.error('Error toggling comment favorite:', error);
      }
    },
    formatDate(timestamp) {
      if (!timestamp) return '';
      
      if (timestamp && timestamp.constructor && timestamp.constructor.name === '__PRIVATE_ServerTimestampFieldValueImpl') {
        return 'Just now';
      }
      
      let date;
      if (timestamp instanceof Date) {
        date = timestamp;
      } else if (timestamp.toDate && typeof timestamp.toDate === 'function') {
        date = timestamp.toDate();
      } else if (timestamp.seconds && timestamp.nanoseconds) {
        date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
      } else if (typeof timestamp === 'number') {
        date = new Date(timestamp);
      } else if (typeof timestamp === 'string') {
        date = new Date(timestamp);
      } else {
        console.error('Unrecognized timestamp format:', timestamp);
        return 'Invalid Date';
      }
      
      if (isNaN(date.getTime())) {
        console.error('Invalid date:', timestamp);
        return 'Invalid Date';
      }
      
      const now = new Date();
      const diffInSeconds = Math.floor((now - date) / 1000);
      
      if (diffInSeconds < 60) {
        return 'Just now';
      } else if (diffInSeconds < 3600) {
        const minutes = Math.floor(diffInSeconds / 60);
        return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
      } else if (diffInSeconds < 86400) {
        const hours = Math.floor(diffInSeconds / 3600);
        return `${hours} hour${hours > 1 ? 's' : ''} ago`;
      } else if (diffInSeconds < 604800) {
        const days = Math.floor(diffInSeconds / 86400);
        return `${days} day${days > 1 ? 's' : ''} ago`;
      } else if (diffInSeconds < 2592000) {
        const weeks = Math.floor(diffInSeconds / 604800);
        return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
      } else if (diffInSeconds < 31536000) {
        const months = Math.floor(diffInSeconds / 2592000);
        return `${months} month${months > 1 ? 's' : ''} ago`;
      } else {
        const years = Math.floor(diffInSeconds / 31536000);
        return `${years} year${years > 1 ? 's' : ''} ago`;
      }
    },
    toggleSearch() {
      this.isSearchExpanded = !this.isSearchExpanded;
    },
    expandSearch() {
      this.isSearchExpanded = true;
    },
    selectComment(comment) {
      this.selectedComment = comment;
    },
    closeCommentModal() {
      this.selectedComment = null;
    },
    editComment(comment) {
      this.editingComment = comment;
      this.newComment = comment.content;
      this.closeCommentModal();
    },
    replyToComment() {
      this.replyingToComment = this.selectedComment;
      this.newComment = '';
      this.closeCommentModal();
    },
    cancelEditOrReply() {
      this.editingComment = null;
      this.replyingToComment = null;
      this.newComment = '';
    },
    showComments(post) {
      this.selectedPost = post;
    }
  },
  mounted() {
    this.loadPosts();
  }
};
</script>

<style scoped>
.forum-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  padding-bottom: 80px;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.back-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  margin-right: 10px;
}

.posts-container {
  margin-bottom: 20px;
}

.post-card {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
}

.post-card:hover {
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.post-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.post-meta, .comment-meta {
  font-size: 0.8em;
  color: #666;
  margin-top: 10px;
}

.post-actions, .comment-actions {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.post-actions button, .comment-actions button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1em;
  color: #666;
  transition: color 0.3s ease;
}

.post-actions button:hover, .comment-actions button:hover {
  color: #333;
}

.post-actions button.favorited, .comment-actions button.favorited {
  color: gold;
}

.delete-button {
  background-color: #ff4136;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 0.9em;
  transition: background-color 0.3s ease;
}

.delete-button:hover {
  background-color: #d50000;
}

.card-library-search {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-out;
  transform: translateY(calc(100% - 40px));
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.card-library-search.expanded {
  transform: translateY(0);
}

.search-handle {
  height: 40px;
  background: #f0f0f0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.search-content {
  padding: 20px;
  max-height: 80vh;
  overflow-y: auto;
}

.new-post-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.new-post-form input,
.new-post-form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.new-post-form textarea {
  height: 100px;
  resize: vertical;
}

.new-post-form button {
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.new-post-fab {
  position: fixed;
  bottom: 80px;
  right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #4CAF50;
  color: white;
  font-size: 24px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.post-details-modal, .comment-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
}

.close-modal-button {
  float: right;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.comments-section {
  margin-top: 20px;
}

.comment-container {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
}

.comment-container:hover {
  background-color: #f5f5f5;
}

.comment {
  padding: 10px 0;
}

.comment-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8em;
  color: #666;
  margin-top: 10px;
}

.comment-actions {
  display: flex;
  gap: 10px;
}

.comment-actions button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 0.9em;
  color: #666;
  transition: color 0.3s ease;
}

.comment-actions button:hover {
  color: #333;
}

.comment-actions button.favorited {
  color: gold;
}

.comment-actions .delete-button {
  background-color: #ff4136;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 2px 8px;
  font-size: 0.9em;
  transition: background-color 0.3s ease;
}

.comment-actions .delete-button:hover {
  background-color: #d50000;
}

.comment-form {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.comment-form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: vertical;
  height: 80px;
}

.comment-form button {
  align-self: flex-end;
  padding: 8px 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Responsive styles */
@media (max-width: 768px) {
  .forum-page {
    padding: 10px;
    padding-bottom: 60px;
  }

  .post-card {
    padding: 10px;
  }

  .post-actions {
    flex-wrap: wrap;
  }

  .new-post-fab {
    bottom: 60px;
    right: 10px;
    width: 50px;
    height: 50px;
    font-size: 20px;
  }

  .modal-content {
    width: 95%;
    max-height: 90vh;
  }

  .comment-form button {
    width: 100%;
  }
}

@media (min-width: 769px) {
  .forum-page {
    padding: 30px;
    padding-bottom: 100px;
  }

  .post-card {
    transition: box-shadow 0.3s ease;
  }

  .post-card:hover {
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }

  .new-post-fab {
    bottom: 40px;
    right: 40px;
    transition: transform 0.3s ease;
  }

  .new-post-fab:hover {
    transform: scale(1.1);
  }

  .card-library-search {
    max-width: 600px;
    left: 50%;
    transform: translateX(-50%) translateY(calc(100% - 40px));
  }

  .card-library-search.expanded {
    transform: translateX(-50%) translateY(0);
  }

  .modal-content {
    padding: 30px;
  }
}

/* Ensure the page content doesn't shift when the scrollbar appears */
html {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

body {
  padding-right: calc(100vw - 100%);
}

/* Scrollbar styles for webkit browsers */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>