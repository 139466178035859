import Vue from 'vue'
import App from './App.vue'
import { app, analytics, db, storage } from './firebase'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faSearch, faUserCircle, faBars, faStar, faEllipsisV, faCog, faInfoCircle, faQuestionCircle, faFileAlt, faNewspaper, faEnvelope, faSignOutAlt, faShieldAlt, faGamepad, faBoxOpen, faUserShield, faComments } from '@fortawesome/free-solid-svg-icons'
import { setupSessionTimeout } from './utils/sessionTimeout'

library.add(faSearch, faUserCircle, faBars, faStar, faEllipsisV, faCog, faInfoCircle, faQuestionCircle, faFileAlt, faNewspaper, faEnvelope, faSignOutAlt, faShieldAlt, faGamepad, faBoxOpen, faUserShield, faComments)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false
Vue.use(VueAxios, axios)
// Make Firebase services available to all components
Vue.prototype.$firebase = {
  app: app,
  analytics: analytics,
  db: db,
  storage: storage
}

new Vue({
  router,
  store,
  render: h => h(App),
  created() {
    this.$store.dispatch('initializeAuth')
    setupSessionTimeout()
  }
}).$mount('#app')