<template>
  <div v-if="showWarning" class="session-warning-dialog">
    <div class="dialog-content">
      <h2>Session Expiring Soon</h2>
      <p>Your session will expire in 1 minute. Would you like to stay logged in?</p>
      <button @click="keepLoggedIn" class="extend-button">Yes, keep me logged in</button>
      <button @click="logout" class="logout-button">No, log me out</button>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { extendSession } from '@/utils/sessionTimeout';
import { getAuth } from 'firebase/auth';

export default {
  name: 'SessionWarningDialog',
  computed: {
    ...mapState(['showSessionWarning']),
    showWarning() {
      return this.showSessionWarning;
    }
  },
  methods: {
    ...mapMutations(['SET_SESSION_WARNING']),
    keepLoggedIn() {
      extendSession();
      this.SET_SESSION_WARNING(false);
    },
    async logout() {
      const auth = getAuth();
      await auth.signOut();
      await this.$store.dispatch('setUser', null);
      this.SET_SESSION_WARNING(false);
      this.$router.push('/login');
    }
  }
}
</script>

<style scoped>
.session-warning-dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.dialog-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.extend-button, .logout-button {
  margin: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.extend-button {
  background-color: #4CAF50;
  color: white;
}

.logout-button {
  background-color: #f44336;
  color: white;
}
</style>