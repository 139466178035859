<template>
    <div 
      class="card" 
      :class="{ 'dragging': isDragging }"
      @mousedown="startDrag"
      @touchstart="startDrag"
    >
      <img v-if="card.imageUrl" :src="card.imageUrl" :alt="card.name" class="card-image" @error="handleImageError">
      <div v-else class="card-name">{{ card.name }}</div>
      <div class="card-type">{{ card.cardType }}</div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Card',
    props: {
      card: {
        type: Object,
        required: true
      },
      draggable: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        isDragging: false
      };
    },
    methods: {
      handleImageError(e) {
        e.target.style.display = 'none';
        this.$el.querySelector('.card-name').style.display = 'block';
      },
      startDrag(event) {
        if (!this.draggable) return;
        
        this.isDragging = true;
        
        if (event.type === 'mousedown') {
          document.addEventListener('mousemove', this.drag);
          document.addEventListener('mouseup', this.endDrag);
        } else if (event.type === 'touchstart') {
          document.addEventListener('touchmove', this.drag);
          document.addEventListener('touchend', this.endDrag);
        }
        
        this.$emit('dragstart', {
          card: this.card,
          event: event
        });
      },
      drag(event) {
        if (!this.isDragging) return;
        
        const clientX = event.clientX || event.touches[0].clientX;
        const clientY = event.clientY || event.touches[0].clientY;
        
        this.$emit('drag', {
          card: this.card,
          x: clientX,
          y: clientY
        });
      },
      endDrag() {
        this.isDragging = false;
        document.removeEventListener('mousemove', this.drag);
        document.removeEventListener('mouseup', this.endDrag);
        document.removeEventListener('touchmove', this.drag);
        document.removeEventListener('touchend', this.endDrag);
        
        this.$emit('dragend', {
          card: this.card
        });
      }
    }
  };
  </script>
  
  <style scoped>
  .card {
    width: 100%;
    height: 100%;
    border: 2px solid #000;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    cursor: pointer;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    transition: transform 0.2s ease;
    touch-action: none; /* Prevents default touch actions */
  }
  
  .card:hover {
    transform: scale(1.05);
  }
  
  .card.dragging {
    opacity: 0.7;
    transform: scale(1.05);
  }
  
  .card-image {
    width: 100%;
    height: 80%;
    object-fit: cover;
  }
  
  .card-name {
    text-align: center;
    padding: 5px;
    font-weight: bold;
    display: none;
  }
  
  .card-type {
    background-color: #f0f0f0;
    width: 100%;
    text-align: center;
    padding: 5px;
    font-size: 0.8em;
    text-transform: capitalize;
  }
  </style>