<template>
  <div class="auth-container" :style="{ backgroundColor: $store.state.appColors.secondary }">
    <div class="auth-content">
      <div class="logo-container">
        <!--img src="@/assets/favicon.webp" alt="iMonster Logo" class="logo">-->
      </div>
      <h1 v-if="mode === 'signin'" class="app-title" :style="{ color: $store.state.appColors.primary }">iMonster</h1>
      <p v-if="mode === 'signin'" class="app-description">Effortlessly manage and track your Pokémon card collection with our intuitive inventory system.</p>
      
      <div v-if="mode === 'signin'" class="auth-form">
        <h2>Sign In</h2>
        <div v-if="error" class="error-message">{{ error }}</div>
        <form @submit.prevent="handleSignIn">
          <div class="input-group">
            <input v-model="email" type="email" placeholder="Email" required>
          </div>
          <div class="input-group">
            <input v-model="password" type="password" placeholder="Password" required>
          </div>
          <button type="submit" :disabled="loading" :style="buttonStyle">Sign In</button>
        </form>
        <p class="auth-switch">Don't have an account? <a @click="mode = 'signup'" href="#">Sign Up</a></p>
        <p class="auth-forgot"><a @click="mode = 'resetPassword'" href="#">Forgot Password?</a></p>
      </div>

      <div v-else-if="mode === 'signup'" class="auth-form">
        <div v-if="error" class="error-message">{{ error }}</div>
        <form @submit.prevent="handleSignUp">
          <div v-if="signupStep === 1" class="avatar-step">
            <h2>Create Your Avatar</h2>
            <AvatarGenerator 
              ref="avatarGenerator"
              @avatar-generated="onAvatarGenerated"
              :initialAvatarData="avatarData"
              :currentIndexes="avatarCurrentIndexes"
              :selectedColors="avatarSelectedColors"
              @feature-changed="onFeatureChanged"
              @color-changed="onColorChanged"
            />
            <div class="button-group">
              <button type="button" @click="goToNextStep" :disabled="!avatarData" :style="buttonStyle">Next</button>
            </div>
          </div>

          <div v-else-if="signupStep === 2">
            <h2>Questionnaire</h2>
            <div class="form-group">
              <label for="fan-duration">How long have you been a Pokémon fan?</label>
              <select v-model="fanDuration" id="fan-duration" required>
                <option value="">Please select</option>
                <option value="less-than-year">Less than a year</option>
                <option value="1-5-years">1-5 years</option>
                <option value="5-10-years">5-10 years</option>
                <option value="10-plus-years">10+ years</option>
                <option value="since-beginning">Since the beginning (1996)</option>
              </select>
            </div>
            <div class="form-group">
              <label>What's your primary interest in Pokémon? (Select all that apply)</label>
              <div class="checkbox-group">
                <div class="checkbox-item">
                  <input type="checkbox" v-model="interests" value="collecting-cards">
                  <label for="collecting-cards">Collecting cards</label>
                </div>
                <div class="checkbox-item">
                  <input type="checkbox" v-model="interests" value="playing-tcg">
                  <label for="playing-tcg">Playing the trading card game</label>
                </div>
                <div class="checkbox-item">
                  <input type="checkbox" v-model="interests" value="video-games">
                  <label for="video-games">Video games</label>
                </div>
                <div class="checkbox-item">
                  <input type="checkbox" v-model="interests" value="anime-movies">
                  <label for="anime-movies">Anime/Movies</label>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="favorite-gen">Which Pokémon generation is your favorite?</label>
              <select v-model="favoriteGen" id="favorite-gen" required>
                <option value="">Please select</option>
                <option value="gen-i">Gen I (Red/Blue/Yellow)</option>
                <option value="gen-ii">Gen II (Gold/Silver/Crystal)</option>
                <option value="gen-iii">Gen III (Ruby/Sapphire/Emerald)</option>
                <option value="gen-iv">Gen IV (Diamond/Pearl/Platinum)</option>
                <option value="gen-v">Gen V (Black/White)</option>
                <option value="gen-vi">Gen VI (X/Y)</option>
                <option value="gen-vii">Gen VII (Sun/Moon)</option>
                <option value="gen-viii">Gen VIII (Sword/Shield)</option>
                <option value="gen-ix">Gen IX (Scarlet/Violet)</option>
              </select>
            </div>
            <div class="form-group">
              <label for="favorite-type">What's your favorite type of Pokémon?</label>
              <select v-model="favoriteType" id="favorite-type" required>
                <option value="">Please select</option>
                <option value="normal">Normal</option>
                <option value="fire">Fire</option>
                <option value="water">Water</option>
                <option value="grass">Grass</option>
                <option value="electric">Electric</option>
                <option value="ice">Ice</option>
                <option value="fighting">Fighting</option>
                <option value="poison">Poison</option>
                <option value="ground">Ground</option>
                <option value="flying">Flying</option>
                <option value="psychic">Psychic</option>
                <option value="bug">Bug</option>
                <option value="rock">Rock</option>
                <option value="ghost">Ghost</option>
                <option value="dragon">Dragon</option>
                <option value="dark">Dark</option>
                <option value="steel">Steel</option>
                <option value="fairy">Fairy</option>
              </select>
            </div>
            <div class="button-group">
              <button type="button" @click="goToPreviousStep" :style="buttonStyle">Previous</button>
              <button type="button" @click="goToNextStep" :style="buttonStyle">Next</button>
            </div>
          </div>

          <div v-else-if="signupStep === 3">
            <h2>Sign Up</h2>
            <div class="input-group">
              <input v-model="email" type="email" placeholder="Email" required>
            </div>
            <div class="input-group">
              <input v-model="username" type="text" placeholder="Username" required>
            </div>
            <div class="input-group">
              <input v-model="firstName" type="text" placeholder="First Name" required>
            </div>
            <div class="input-group">
              <input v-model="lastName" type="text" placeholder="Last Name" required>
            </div>
            <div class="input-group">
              <input v-model="age" type="number" placeholder="Age" required>
            </div>
            <div class="input-group">
              <input v-model="country" type="text" placeholder="Country" required>
            </div>
            <div class="input-group">
              <input v-model="password" type="password" placeholder="Password" required>
            </div>
            <div class="button-group">
              <button type="button" @click="goToPreviousStep" :style="buttonStyle">Previous</button>
              <button type="submit" :disabled="loading" :style="buttonStyle">Sign Up</button>
            </div>
          </div>
        </form>
        <p class="auth-switch">Already have an account? <a @click="mode = 'signin'" href="#">Sign In</a></p>
      </div>

      <div v-else-if="mode === 'resetPassword'" class="auth-form">
        <h2>Reset Password</h2>
        <div v-if="error" class="error-message">{{ error }}</div>
        <form @submit.prevent="handleResetPassword">
          <div class="input-group">
            <input v-model="email" type="email" placeholder="Email" required>
          </div>
          <button type="submit" :disabled="loading" :style="buttonStyle">Reset Password</button>
        </form>
        <p class="auth-switch"><a @click="mode = 'signin'" href="#">Back to Sign In</a></p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { getFirestore, doc, setDoc, getDoc, serverTimestamp, collection, getDocs } from 'firebase/firestore';
import { getStorage, ref, uploadString } from 'firebase/storage';
import AvatarGenerator from '../components/AvatarGenerator.vue';

export default {
  name: 'AuthComponent',
  components: {
    AvatarGenerator,
  },
  data() {
    return {
      mode: 'signin',
      signupStep: 1,
      email: '',
      password: '',
      username: '',
      firstName: '',
      lastName: '',
      age: '',
      country: '',
      fanDuration: '',
      interests: [],
      favoriteGen: '',
      favoriteType: '',
      loading: false,
      error: '',
      avatarData: null,
      avatarCurrentIndexes: {},
      avatarSelectedColors: {
        Clothes: 'Black',
        Eyebrows: 'Black',
        Eyes: 'Black',
        Hair: 'Black'
      },
    };
  },
  computed: {
    buttonStyle() {
      return {
        backgroundColor: this.$store.state.appColors.primary,
        color: this.$store.state.appColors.secondary
      };
    }
  },
  methods: {
    ...mapActions(['setUser', 'updateFavoriteType']),
    async handleSignIn() {
      this.loading = true;
      this.error = '';
      try {
        const auth = getAuth();
        const db = getFirestore();
        const userCredential = await signInWithEmailAndPassword(auth, this.email, this.password);
        const user = userCredential.user;
        
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        const userData = userDoc.data();
        
        this.setUser({
          ...user,
          isAdmin: userData?.isAdmin || false,
          ...userData
        });
        
        this.$emit('sign-in');
      } catch (err) {
        console.error("Error signing in:", err);
        this.error = "Failed to sign in. Please check your credentials and try again.";
      } finally {
        this.loading = false;
      }
    },
    async handleSignUp() {
      this.loading = true;
      this.error = '';
      
      try {
        if (this.signupStep === 3) {
          if (this.password.length < 6) {
            throw new Error('Password must be at least 6 characters long.');
          }

          if (!this.avatarData) {
            throw new Error('Avatar data is not available');
          }

          const auth = getAuth();
          const db = getFirestore();
          const storage = getStorage();
          const userCredential = await createUserWithEmailAndPassword(auth, this.email, this.password);
          const user = userCredential.user;

          const avatarRef = ref(storage, `avatars/${user.uid}.png`);
          await uploadString(avatarRef, this.avatarData.imageData, 'data_url');

          // Get the next user number
          const usersRef = collection(db, 'users');
          const userSnapshots = await getDocs(usersRef);
          const userNumber = userSnapshots.size + 1;

          const userData = {
            username: this.username,
            firstName: this.firstName,
            lastName: this.lastName,
            age: this.age,
            country: this.country,
            email: this.email,
            isAdmin: false,
            createdAt: serverTimestamp(),
            fanDuration: this.fanDuration,
            interests: this.interests,
            favoriteGen: this.favoriteGen,
            favoriteType: this.favoriteType,
            avatar: {
              features: this.avatarData.features,
              colors: this.avatarData.colors
            },
            userNumber: userNumber
          };

          await setDoc(doc(db, 'users', user.uid), userData);

          this.updateFavoriteType(this.favoriteType);
          this.setUser({
            ...user,
            ...userData
          });

          this.$emit('sign-in');
        } else {
          // If not on step 3, do nothing and let the form handle moving to the next step
          return;
        }
      } catch (err) {
        console.error("Error signing up:", err);
        if (this.signupStep === 3) {
          if (err.message === 'Password must be at least 6 characters long.') {
            this.error = err.message;
          } else if (err.code === 'auth/email-already-in-use') {
            this.error = "This email is already in use. Please use a different email or sign in.";
          } else {
            this.error = "Failed to sign up. Please try again.";
          }
        } else {
          this.error = "An error occurred. Please try again.";
        }
      } finally {
        this.loading = false;
      }
    },
    async handleResetPassword() {
      this.loading = true;
      this.error = '';
      try {
        const auth = getAuth();
        await sendPasswordResetEmail(auth, this.email);
        alert('Password reset email sent. Please check your inbox.');
        this.mode = 'signin';
      } catch (err) {
        console.error("Error sending password reset email:", err);
        this.error = "Failed to send password reset email. Please try again.";
      } finally {
        this.loading = false;
      }
    },
    onAvatarGenerated(avatarData) {
      this.avatarData = avatarData;
    },
    onFeatureChanged(feature, index) {
      this.avatarCurrentIndexes[feature] = index;
    },
    onColorChanged(feature, color) {
      this.avatarSelectedColors[feature] = color;
    },
    goToNextStep() {
      if (this.signupStep < 3) {
        this.signupStep++;
      }
    },
    goToPreviousStep() {
      if (this.signupStep > 1) {
        this.signupStep--;
      }
    }
  }
};
</script>

<style scoped>
.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  background-color: #f0f0f0;
}

.auth-content {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
  overflow-y: auto;
  max-height: 90vh;
}

.logo-container {
  margin-bottom: 1rem;
}

.logo {
  width: 80px;
  height: auto;
}

.app-title {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.app-description {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 1rem;
}

.auth-form {
  margin-top: 1rem;
}

h2 {
  color: #333;
  margin: 10px;
}

.input-group {
  margin-bottom: 1rem;
}

input, select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

button {
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
  margin-top: 1rem;
}

button:hover:not(:disabled) {
  opacity: 0.9;
}

button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.auth-switch, .auth-forgot {
  margin-top: 1rem;
  font-size: 0.9rem;
}

.form-group {
  margin-bottom: 1rem;
  text-align: left;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
}

.checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.checkbox-item {
  display: flex;
  align-items: center;
}

.checkbox-item input[type="checkbox"] {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}

.checkbox-item label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  gap: 1rem;
}

.button-group button {
  width: 100%;
}

a {
  color: #4CAF50;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.error-message {
  color: #f44336;
  margin-bottom: 1rem;
}

.avatar-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

.avatar-step h3 {
  margin-bottom: 1rem;
}

/* Responsive styles */
@media (max-width: 480px) {
  .auth-content {
    padding: 1.5rem;
    max-height: 100vh;
    border-radius: 0;
  }

  .app-title {
    font-size: 1.5rem;
  }

  .app-description {
    font-size: 0.8rem;
  }

  input, button {
    font-size: 0.9rem;
  }

  .button-group {
    flex-direction: column;
  }

  .button-group button {
    width: 100%;
    margin-top: 0.5rem;
  }

  .avatar-step {
    transform: scale(0.9);
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .auth-content {
    padding: 1.5rem;
    max-height: 100vh;
    border-radius: 0;
  }

  .app-title {
    font-size: 1.5rem;
  }

  .app-description {
    font-size: 0.8rem;
  }

  input, button {
    font-size: 0.9rem;
  }

  .button-group {
    flex-direction: column;
  }

  .button-group button {
    width: 100%;
    margin-top: 0.5rem;
  }

  .avatar-step {
    transform: scale(0.9);
  }
}

@media (min-width: 769px) {
  .auth-content {
    padding: 1.5rem;
    max-height: 100vh;
    border-radius: 0;
  }

  .app-title {
    font-size: 1.5rem;
  }

  .app-description {
    font-size: 0.8rem;
  }

  input, button {
    font-size: 0.9rem;
  }

  .button-group {
    flex-direction: column;
  }

  .button-group button {
    width: 100%;
    margin-top: 0.5rem;
  }

  .avatar-step {
    transform: scale(0.9);
  }
}
</style>