<template>
  <div class="card-form" :class="{ 'mobile': isMobile }">
    <div class="modal-card-image-container" @click="toggleEnlargedImage">
      <img 
        :src="card.imageUrl" 
        :alt="card.name" 
        class="modal-card-image" 
        :class="{ 'enlarged': isImageEnlarged, 'transitioning': isTransitioning }"
      >
    </div>
    <div v-if="isMobile" class="form-grid">
      <div class="input-wrapper" v-for="field in mobileFields" :key="field.id">
        <input 
          v-if="field.type !== 'select' && field.type !== 'textarea'"
          :type="field.type || 'text'"
          :id="field.id"
          :step="field.step"
          v-model="formData[field.id.replace(/-/g, '')]"
          :max="field.id === 'quantity' ? maxQuantity : undefined"
        >
        <select 
          v-else-if="field.type === 'select'"
          :id="field.id"
          v-model="formData[field.id.replace(/-/g, '')]"
        >
          <option value="" disabled selected hidden>Select an option</option>
          <option v-for="option in field.options" :key="option" :value="option">{{ option }}</option>
        </select>
        <textarea 
          v-else
          :id="field.id"
          v-model="formData[field.id.replace(/-/g, '')]"
        ></textarea>
        <label :for="field.id">{{ field.label }}</label>
      </div>
    </div>
    <div v-else class="desktop-form">
      <div class="form-columns">
        <div v-for="(group, groupIndex) in fieldGroups" :key="groupIndex" class="form-column">
          <div v-for="field in group" :key="field.id" class="field-container">
            <div class="input-wrapper">
              <input 
                v-if="field.type !== 'select' && field.type !== 'textarea'"
                :type="field.type || 'text'"
                :id="field.id"
                :step="field.step"
                v-model="formData[field.id.replace(/-/g, '')]"
                :max="field.id === 'quantity' ? maxQuantity : undefined"
              >
              <select 
                v-else-if="field.type === 'select'"
                :id="field.id"
                v-model="formData[field.id.replace(/-/g, '')]"
              >
                <option value="" disabled selected hidden>Select an option</option>
                <option v-for="option in field.options" :key="option" :value="option">{{ option }}</option>
              </select>
              <textarea 
                v-else
                :id="field.id"
                v-model="formData[field.id.replace(/-/g, '')]"
              ></textarea>
              <label :for="field.id">{{ field.label }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="notes-container">
      <div class="input-wrapper">
        <textarea id="notes" v-model="formData.notes"></textarea>
        <label for="notes">Notes</label>
      </div>
    </div>
    <button class="add-card-button" @click="submitForm" :disabled="!isValid">
      {{ editingIndex !== null ? 'Update Card' : 'Add Card' }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'CardForm',
  props: {
    card: {
      type: Object,
      default: () => ({})
    },
    editingIndex: {
      type: Number,
      default: null
    },
    totalCards: {
      type: Number,
      required: true
    }
  },
  data() {
    const fieldGroups = [
      [
        { label: 'Name', id: 'name' },
        { label: 'Card Type', id: 'card-type', type: 'select', options: ['Pokémon', 'Trainer', 'Stadium', 'Item', 'Tool', 'Supporter', 'Basic Energy', 'Special Energy'] },
        { label: 'Rarity', id: 'rarity' },
        { label: 'Grading Company', id: 'grading-company', type: 'select', options: ['None', 'PSA', 'BGS', 'CGC'] },
        { label: 'Cert Num', id: 'grading-cert' },
        { label: 'Purchase Date', id: 'purchase-date', type: 'date' },
        { label: 'Market Value', id: 'market-value', type: 'number', step: '0.01' },
      ],
      [
        { label: 'Set Box', id: 'set-box' },
        { label: 'Energy Type', id: 'energy-type', type: 'select', options: ['Colorless', 'Grass', 'Fire', 'Water', 'Lightning', 'Psychic', 'Fighting', 'Darkness', 'Metal', 'Fairy', 'Dragon', 'None'] },
        { label: 'Print Run', id: 'print-run', type: 'select', options: ['Unlimited', '1st Edition', 'Shadowless', 'Limited'] },
        { label: 'Condition', id: 'condition', type: 'select', options: ['Near Mint (NM)', 'Lightly Played (LP)', 'Moderately Played (MP)', 'Heavily Played (HP)', 'Damaged (DMG)'] },
        { label: 'Weight (g)', id: 'weight', type: 'number', step: '0.01' },
        { label: 'Purchase Price', id: 'purchase-price', type: 'number', step: '0.01' },
        { label: 'Previous Market Value', id: 'prev-market-value', type: 'number', step: '0.01' },
      ],
      [
        { label: 'Quantity', id: 'quantity', type: 'number' },
        { label: 'Language', id: 'language', type: 'select', options: ['English', 'Japanese', 'French', 'German', 'Italian', 'Spanish', 'Korean', 'Chinese'] },
        { label: 'Foil Pattern', id: 'foil-pattern', type: 'select', options: ['None', 'Holo', 'Reverse Holo', 'Full Art', 'Rainbow Rare', 'Gold Rare'] },
        { label: 'Grade', id: 'grade' },
        { label: 'Thickness (mm)', id: 'thickness', type: 'number', step: '0.01' },
      ]
    ];
    return {
      formData: {},
      fieldGroups,
      mobileFields: fieldGroups.flat(),
      isImageEnlarged: false,
      isTransitioning: false,
    };
  },
  computed: {
    isMobile() {
      return window.innerWidth < 768;
    },
    maxQuantity() {
      const remainingSlots = 60 - this.totalCards;
      return this.editingIndex !== null 
        ? this.formData.quantity + remainingSlots 
        : remainingSlots;
    },
    isValid() {
      return this.formData.name &&
             this.formData.cardtype &&
             this.formData.quantity > 0 &&
             this.formData.quantity <= this.maxQuantity;
    }
  },
  watch: {
    card: {
      immediate: true,
      handler(newCard) {
        this.formData = {
          name: newCard?.name || '',
          cardtype: newCard?.cardType || newCard?.card_type || '',
          rarity: newCard?.rarity || '',
          gradingcompany: newCard?.gradingCompany || 'None',
          gradingcert: newCard?.gradingCert || '',
          purchasedate: newCard?.purchaseDate || '',
          marketvalue: newCard?.marketValue || '',
          setbox: newCard?.setBox || '',
          energytype: newCard?.energyType || newCard?.energy_type || '',
          printrun: newCard?.printRun || 'Unlimited',
          condition: newCard?.condition || 'Near Mint',
          weight: newCard?.weight || '',
          purchaseprice: newCard?.purchasePrice || '',
          prevmarketvalue: newCard?.prevMarketValue || '',
          quantity: newCard?.quantity || 1,
          language: newCard?.language || 'English',
          foilpattern: newCard?.foilPattern || 'None',
          grade: newCard?.grade || '',
          thickness: newCard?.thickness || '',
          notes: newCard?.notes || ''
        };
      }
    }
  },
  methods: {
    toggleEnlargedImage() {
      this.isTransitioning = true;
      this.isImageEnlarged = !this.isImageEnlarged;
      
      // Remove the transitioning class after the animation completes
      setTimeout(() => {
        this.isTransitioning = false;
      }, 300); // This should match the transition duration in CSS
    },
    submitForm() {
      if (!this.isValid) return;

      const cardData = {
        name: this.formData.name,
        cardType: this.formData.cardtype,
        rarity: this.formData.rarity,
        gradingCompany: this.formData.gradingcompany,
        gradingCert: this.formData.gradingcert,
        purchaseDate: this.formData.purchasedate,
        marketValue: this.formData.marketvalue,
        setBox: this.formData.setbox,
        energyType: this.formData.energytype,
        printRun: this.formData.printrun,
        condition: this.formData.condition,
        weight: this.formData.weight,
        purchasePrice: this.formData.purchaseprice,
        prevMarketValue: this.formData.prevmarketvalue,
        quantity: parseInt(this.formData.quantity) || 0,
        language: this.formData.language,
        foilPattern: this.formData.foilpattern,
        grade: this.formData.grade,
        thickness: this.formData.thickness,
        notes: this.formData.notes
      };

      if (this.card && this.card.imageUrl) {
        cardData.imageUrl = this.card.imageUrl;
      }

      if (this.editingIndex !== null) {
        this.$emit('update-card', { index: this.editingIndex, card: cardData });
      } else {
        this.$emit('add-card', cardData);
      }
      this.resetForm();
    },
    resetForm() {
      this.formData = {
        name: '',
        cardtype: '',
        rarity: '',
        gradingcompany: 'None',
        gradingcert: '',
        purchasedate: '',
        marketvalue: '',
        setbox: '',
        energytype: '',
        printrun: 'Unlimited',
        condition: 'Near Mint',
        weight: '',
        purchaseprice: '',
        prevmarketvalue: '',
        quantity: 1,
        language: 'English',
        foilpattern: 'None',
        grade: '',
        thickness: '',
        notes: ''
      };
    }
  }
};
</script>

<style scoped>
.card-form {
  max-width: 1000px;
  width: 100%;
}

.form-columns {
  display: flex;
  gap: 50px;
}

.form-column {
  flex: 1;
}

.field-container {
  margin-bottom: 15px;
}

.input-wrapper {
  position: relative;
  margin-bottom: 20px;
}

.input-wrapper input,
.input-wrapper select,
.input-wrapper textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: transparent;
  transition: border-color 0.3s;
}

.input-wrapper label {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  padding: 0 5px;
  font-size: 14px;
  color: #666;
  transition: all 0.3s;
  pointer-events: none;
}

.input-wrapper input:focus,
.input-wrapper select:focus,
.input-wrapper textarea:focus {
  outline: none;
  border-color: #4CAF50;
}

.input-wrapper input:focus + label,
.input-wrapper select:focus + label,
.input-wrapper textarea:focus + label,
.input-wrapper input:not(:placeholder-shown) + label,
.input-wrapper select:not(:placeholder-shown) + label,
.input-wrapper textarea:not(:placeholder-shown) + label {
  top: 0;
  font-size: 12px;
  color: #4CAF50;
}

.notes-container {
  margin-top: 20px;
}

.notes-container textarea {
  height: 100px;
  resize: none;
}

.add-card-button {
  display: block;
  width: 100%;
  max-width: 200px;
  margin: 20px auto 0;
  padding: 12px 15px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.add-card-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.modal-card-image-container {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.modal-card-image {
  max-width: 200px;
  height: auto;
  transition: all 0.3s ease;
  transform-origin: center;
}

.modal-card-image.transitioning {
  position: fixed;
  z-index: 1010;
}

.modal-card-image.enlarged {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  max-width: 90vw;
  max-height: 90vh;
  z-index: 1010;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.modal-card-image:not(.enlarged) {
  transform: scale(1);
}

/* Mobile Styles */
.mobile .form-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

@media (max-width: 767px) {
  .modal-card-image {
    max-width: 100px;
  }

  .form-columns {
    flex-direction: column;
    gap: 10px;
  }

  .input-wrapper input,
  .input-wrapper select,
  .input-wrapper textarea {
    font-size: 10px;
    padding: 15px 10px;
  }

  .input-wrapper label {
    font-size: 8px;
  }

  .add-card-button {
    font-size: 10px;
    padding: 5px;
    max-width: 120px;
  }

  .mobile .form-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5px;
  }

  .mobile .input-wrapper {
    margin-bottom: 10px;
  }

  .mobile .input-wrapper input,
  .mobile .input-wrapper select {
    padding: 10px;
    font-size: 10px;
  }

  .mobile .input-wrapper label {
    font-size: 8px;
  }

  .mobile .notes-container {
    margin-top: 10px;
  }

  .mobile .notes-container textarea {
    height: 50px;
    resize: none;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .form-columns {
    gap: 20px;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .form-columns {
    gap: 30px;
  }
}
</style>