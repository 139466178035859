<template>
  <div class="main-menu">
    <div class="header" :style="{ backgroundColor: $store.state.appColors.primary }">
      <h1>Pokémon Card Inventory Manager</h1>
      <div class="header-icons">
        <div class="search-container">
          <input 
            v-model="searchQuery" 
            @input="performSearch" 
            placeholder="Search decks..." 
            class="search-input"
            :class="{ 'active': showSearch }"
          >
          <font-awesome-icon :icon="['fas', 'search']" class="icon search-icon" @click="toggleSearch" />
        </div>
        <font-awesome-icon :icon="['fas', 'ellipsis-v']" class="icon menu-icon" @click="toggleMenu" />
      </div>
    </div>
    <div class="tabs">
      <button :class="{ active: activeTab === 'recents' }" @click="setActiveTab('recents')">RECENTS</button>
      <button :class="{ active: activeTab === 'favorites' }" @click="setActiveTab('favorites')">FAVORITES</button>
      <button :class="{ active: activeTab === 'all' }" @click="setActiveTab('all')">ALL</button>
      <div class="valid-only">
        <span>VALID ONLY</span>
        <input type="checkbox" v-model="validOnly" @change="filterDecks">
      </div>
    </div>
    <div v-if="loading" class="loading"></div>
    <div v-else-if="error" class="error">{{ error }}</div>
    <div v-else class="deck-grid-container">
      <div class="deck-grid">
        <transition-group name="deck-list" tag="div">
          <div v-for="deck in paginatedDecks" :key="deck.id" class="deck-item" @click="editDeck(deck)">
            <div class="deck-image-container">
              <img :src="deck.imageUrl || require('@/assets/placeholder.webp')" :alt="deck.name">
            </div>
            <div class="deck-content">
              <div class="deck-name">{{ deck.name }}</div>
              <div class="deck-details">
                <span class="deck-status" :class="{ invalid: !deck.isValid }">
                  {{ getTotalCards(deck) }}/60 {{ deck.isValid ? 'STANDARD' : 'INVALID' }}
                </span>
                <span class="deck-value">${{ getDeckValue(deck).toFixed(2) }}</span>
              </div>
            </div>
            <div 
              class="favorite-star" 
              :class="{ favorited: deck.favorite }"
              @click.stop="toggleFavorite(deck)"
            >★</div>
          </div>
        </transition-group>
      </div>
    </div>
    <div class="pagination-bar">
      <button @click="prevPage" :disabled="currentPage === 1">Previous</button>
      <span>Page {{ currentPage }} of {{ totalPages }}</span>
      <button @click="nextPage" :disabled="currentPage === totalPages">Next</button>
    </div>
    <button class="create-deck-fab" @click="createNewDeck">
      <span class="fab-icon">+</span>
      <span class="fab-text">Create Inventory</span>
    </button>
    <div v-if="showMenu" class="menu-dropdown">
      <div @click="navigateTo('pack-simulator')">
        <font-awesome-icon :icon="['fas', 'box-open']" /> Pack Simulator
      </div>
      <div @click="navigateTo('profile')">
        <font-awesome-icon :icon="['fas', 'user-circle']" /> Profile
      </div>
      <div @click="navigateTo('forum')">
        <font-awesome-icon :icon="['fas', 'comments']" /> Forum
      </div>
      <div @click="navigateTo('settings')">
        <font-awesome-icon :icon="['fas', 'cog']" /> Settings
      </div>
      <div @click="navigateTo('about')">
        <font-awesome-icon :icon="['fas', 'info-circle']" /> About
      </div>
      <div @click="navigateTo('support')">
        <font-awesome-icon :icon="['fas', 'question-circle']" /> Support
      </div>
      <div @click="navigateTo('terms')">
        <font-awesome-icon :icon="['fas', 'file-alt']" /> Terms of Service
      </div>
      <div @click="navigateTo('privacy')">
        <font-awesome-icon :icon="['fas', 'shield-alt']" /> Privacy Notice
      </div>
      <div @click="navigateTo('news')">
        <font-awesome-icon :icon="['fas', 'newspaper']" /> News
      </div>
      <div @click="navigateTo('inbox')">
        <font-awesome-icon :icon="['fas', 'envelope']" /> Inbox
      </div>
      <div v-if="isAdmin" @click="navigateTo('admin')">
        <font-awesome-icon :icon="['fas', 'user-shield']" /> Admin Dashboard
      </div>
      <div @click="handleSignOut">
        <font-awesome-icon :icon="['fas', 'sign-out-alt']" /> Sign Out
      </div>
      <!-- DON'T DELETE  OR ENABLE THIS -->
      <!--<div @click="navigateTo('battlefield')">
        <font-awesome-icon :icon="['fas', 'gamepad']"/> Battle Field
      </div>-->
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { getAuth, signOut } from 'firebase/auth';
import { db } from '../firebase';
import { collection, query, orderBy, getDocs, updateDoc, doc } from 'firebase/firestore';

export default {
  name: 'MainMenu',
  data() {
    return {
      activeTab: 'recents',
      validOnly: false,
      decks: {},
      loading: true,
      error: null,
      searchQuery: '',
      showSearch: false,
      showMenu: false,
      currentPage: 1,
      mobileDecksPerPage: 11,
      desktopDecksPerPage: 9,
      isMobileView: false
    };
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters(['isAdmin']),
    isAdmin() {
      return this.user && this.user.isAdmin;
    },
    filteredDecks() {
      let filtered = Object.values(this.decks);
      if (this.activeTab === 'favorites') {
        filtered = filtered.filter(deck => deck.favorite);
      }
      if (this.validOnly) {
        filtered = filtered.filter(deck => deck.isValid);
      }
      if (this.searchQuery) {
        const query = this.searchQuery.toLowerCase();
        filtered = filtered.filter(deck => deck.name.toLowerCase().includes(query));
      }
      return filtered;
    },
    decksPerPage() {
      return this.isMobileView ? this.mobileDecksPerPage : this.desktopDecksPerPage;
    },
    paginatedDecks() {
      const start = (this.currentPage - 1) * this.decksPerPage;
      const end = start + this.decksPerPage;
      return this.filteredDecks.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredDecks.length / this.decksPerPage);
    }
  },
  methods: {
    ...mapActions(['setUser']),
    async fetchDecks() {
      if (!this.user) {
        console.log('User not authenticated, skipping deck fetch');
        return;
      }
      this.loading = true;
      this.error = null;
      try {
        const auth = getAuth();
        const userDecksRef = collection(db, 'users', auth.currentUser.uid, 'decks');
        const q = query(userDecksRef, orderBy('lastModified', 'desc'));
        const querySnapshot = await getDocs(q);
        const decks = {};
        querySnapshot.forEach(doc => {
          const deckData = {
            id: doc.id,
            ...doc.data(),
            lastModified: doc.data().lastModified?.toDate() || new Date()
          };
          decks[doc.id] = deckData;
        });
        this.decks = decks;
      } catch (err) {
        console.error("Error fetching decks:", err);
        this.error = "Failed to load decks. Please try again.";
      } finally {
        this.loading = false;
      }
    },
    async toggleFavorite(deck) {
      try {
        const updatedFavoriteStatus = !deck.favorite;
        this.$set(this.decks[deck.id], 'favorite', updatedFavoriteStatus);
        
        const auth = getAuth();
        await updateDoc(doc(db, 'users', auth.currentUser.uid, 'decks', deck.id), { favorite: updatedFavoriteStatus });
      } catch (err) {
        console.error("Error updating favorite status:", err);
        this.$set(this.decks[deck.id], 'favorite', !this.decks[deck.id].favorite);
        this.error = "Failed to update favorite status. Please try again.";
      }
    },
    createNewDeck() {
      this.$emit('create-deck');
    },
    editDeck(deck) {
      this.$emit('edit-deck', deck);
    },
    setActiveTab(tab) {
      this.activeTab = tab;
      this.currentPage = 1;
    },
    filterDecks() {
      this.currentPage = 1;
    },
    performSearch() {
      this.currentPage = 1;
    },
    toggleSearch() {
      this.showSearch = !this.showSearch;
      if (!this.showSearch) {
        this.searchQuery = '';
      }
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
      if (this.showMenu) {
        this.$nextTick(() => {
          document.addEventListener('click', this.closeMenuOutside);
        });
      } else {
        document.removeEventListener('click', this.closeMenuOutside);
      }
    },
    closeMenuOutside(event) {
      if (this.$refs.menuDropdown && !this.$refs.menuDropdown.contains(event.target)) {
        this.showMenu = false;
        document.removeEventListener('click', this.closeMenuOutside);
      }
    },
    navigateTo(page) {
      this.showMenu = false;
      this.$emit('navigate', page);
    },
    async handleSignOut() {
      try {
        const auth = getAuth();
        await signOut(auth);
        // After successful sign-out
        await this.setUser(null);
        this.$emit('sign-out');
      } catch (error) {
        console.error('Error signing out:', error);
      }
    },
    getTotalCards(deck) {
      const total = deck.cards.reduce((sum, card) => sum + (parseInt(card.quantity) || 0), 0);
      return total > 0 ? total : '0';
    },
    getDeckValue(deck) {
      return deck.cards.reduce((total, card) => total + (parseFloat(card.marketValue) || 0) * card.quantity, 0);
    },
    checkMobileView() {
      this.isMobileView = window.innerWidth < 768;
      this.currentPage = 1;
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    }
  },
  mounted() {
    this.fetchDecks();
    this.checkMobileView();
    window.addEventListener('resize', this.checkMobileView);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeMenuOutside);
    window.removeEventListener('resize', this.checkMobileView);
  }
};
</script>

<style scoped>
.main-menu {
  background-color: #ffffff;
  font-family: Arial, sans-serif;
  padding-bottom: 70px;
  max-width: 1200px;
  margin: 0 auto;
}

.header {
  background-color: #cc0000;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
}

.header-icons {
  display: flex;
  align-items: center;
  gap: 20px;
}

.search-container {
  display: flex;
  align-items: center;
  position: relative;
}

.search-input {
  background-color: rgba(255, 255, 255, 0.2);
  border: none;
  border-radius: 20px;
  color: white;
  padding: 8px 30px 8px 15px;
  width: 0;
  transition: width 0.3s ease-in-out;
  opacity: 0;
}

.search-input.active {
  width: 200px;
  opacity: 1;
}

.search-input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.search-icon {
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.icon {
  font-size: 1.5em;
  cursor: pointer;
}

.tabs {
  display: flex;
  background-color: #333333;
  padding: 10px;
  margin-top: 10px;
}

.tabs button {
  background: none;
  border: none;
  color: #cccccc;
  padding: 15px 10px;
  margin-right: 10px;
  cursor: pointer;
}

.tabs button.active {
  color: #ffcb05;
  border-bottom: 2px solid #ffcb05;
}

.valid-only {
  margin-left: auto;
  color: white;
  display: flex;
  align-items: center;
}

.deck-grid-container {
  overflow-y: auto;
  max-height: calc(100vh - 200px);
}

.deck-grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.deck-item {
  width: 640px;
  height: 260px;
  margin-bottom: 20px;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  position: relative;
  cursor: pointer;
  transition: transform 0.3s ease;
  display: flex;
}

.deck-item:hover {
  transform: translateY(-5px);
}

.deck-image-container {
  width: 200px;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.deck-image-container img {
  height: 100%;
  width: auto;
  object-fit: cover;
}

.deck-content {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.deck-name {
  font-weight: bold;
  font-size: 1.5em;
  margin-bottom: 10px;
}

.deck-details {
  display: flex;
  justify-content: space-between;
  font-size: 1.1em;
}

.deck-status {
  color: #4CAF50;
}

.deck-status.invalid {
  color: #f44336;
}

.favorite-star {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  color: #cccccc;
  transition: color 0.3s ease;
}

.favorite-star.favorited {
  color: #ffcb05;
}

.create-deck-fab {
  position: fixed;
  bottom: 80px;
  right: 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 50px;
  padding: 15px 20px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  transition: background-color 0.3s, transform 0.3s;
  z-index: 10;
}

.create-deck-fab:hover {
  background-color: #45a049;
  transform: translateY(-2px);
}

.fab-icon {
  font-size: 24px;
  margin-right: 8px;
}

.fab-text {
  font-weight: bold;
}

.pagination-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  box-shadow: 0 -2px 5px rgba(0,0,0,0.1);
  z-index: 5;
}

.pagination-bar button {
  padding: 5px 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.pagination-bar button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.menu-dropdown {
  position: absolute;
  top: 60px;
  right: 20px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  z-index: 1000;
}

.menu-dropdown div {
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.menu-dropdown div:hover {
  background-color: #f0f0f0;
}

/* Mobile Styles */
@media (max-width: 767px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }

  .header h1 {
    font-size: 1.2em;
    margin-bottom: 10px;
  }

  .header-icons {
    width: 100%;
    justify-content: space-between;
  }

  .search-input.active {
    width: 150px;
  }

  .deck-grid {
    flex-direction: column;
    align-items: stretch;
  }

  .deck-item {
    width: 100%;
    height: 100px;
    margin-bottom: 15px;
    flex-direction: row;
  }

  .deck-image-container {
    width: 70px;
    height: 100px;
  }

  .deck-image-container img {
    width: 80%;
    height: auto;
    object-fit: cover;
  }

  .deck-content {
    padding: 10px;
  }

  .deck-name {
    font-size: 1.2em;
    margin-bottom: 5px;
  }

  .deck-details {
    font-size: 0.9em;
  }

  .create-deck-fab {
    bottom: 70px;
    right: 15px;
    padding: 12px;
    width: 50px;
    height: 50px;
  }
  
  .fab-text {
    display: none;
  }
  
  .fab-icon {
    font-size: 34px;
    padding: 2.5px;
    margin-right: 0;
  }

  .tabs {
    flex-wrap: wrap;
    justify-content: center;
  }

  .valid-only {
    margin-left: 0;
    margin-top: 10px;
    width: 100%;
    justify-content: center;
  }

  .menu-dropdown {
    top: 100px;
    right: 10px;
    left: 10px;
  }
}

/* Tablet Styles */
@media (min-width: 768px) and (max-width: 1023px) {
  .deck-item {
    width: 100%;
    max-width: 640px;
  }

  .search-input.active {
    width: 180px;
  }
}

/* Desktop Styles */
@media (min-width: 1024px) {
  .main-menu {
    max-width: 1200px;
    margin: 0 auto;
  }

  .deck-grid {
    align-items: center;
  }

  .deck-image-container img {
    width: 80%;
    height: auto;
  }

  .menu-dropdown {
    right: calc((100% - 1200px) / 2 + 20px);
  }

  .search-input.active {
    width: 250px;
  }
}
</style>