export const pokemonTypeColors = {
  normal: { primary: '#A8A77A', secondary: '#C6C5B0' },
  fire: { primary: '#EE8130', secondary: '#F4B088' },
  water: { primary: '#6390F0', secondary: '#9DB7F5' },
  electric: { primary: '#F7D02C', secondary: '#FAE078' },
  grass: { primary: '#7AC74C', secondary: '#A7DB8D' },
  ice: { primary: '#96D9D6', secondary: '#BCE6E6' },
  fighting: { primary: '#C22E28', secondary: '#D67873' },
  poison: { primary: '#A33EA1', secondary: '#C183C1' },
  ground: { primary: '#E2BF65', secondary: '#EBD69D' },
  flying: { primary: '#A98FF3', secondary: '#C6B7F5' },
  psychic: { primary: '#F95587', secondary: '#FA92B2' },
  bug: { primary: '#A6B91A', secondary: '#C6D16E' },
  rock: { primary: '#B6A136', secondary: '#D1C17D' },
  ghost: { primary: '#735797', secondary: '#A292BC' },
  dragon: { primary: '#6F35FC', secondary: '#A27DFA' },
  dark: { primary: '#705746', secondary: '#A29288' },
  steel: { primary: '#B7B7CE', secondary: '#D1D1E0' },
  fairy: { primary: '#D685AD', secondary: '#F4BDC9' }
};