<template>
  <div class="pack-simulator">
    <h1>Twilight Masquerade Pack Simulator</h1>
    <div v-if="loading" class="loading">Loading cards...</div>
    <div v-else-if="error" class="error">{{ error }}</div>
    <div v-else class="simulator-content">
      <div v-if="!isPackOpen" class="pack-container" @click="openNewPack">
        <img :src="packImage" alt="Twilight Masquerade Pack" class="pack-image">
      </div>
      <div v-if="isPackOpen" class="cards-container">
        <div 
          v-for="(card, index) in cards" 
          :key="index" 
          class="card" 
          :class="{ 'visible': index === currentCardIndex }"
          @touchstart="handleCardTouchStart"
          @touchmove="handleCardTouchMove"
          @touchend="handleCardTouchEnd"
          @mousedown="handleMouseDown"
          @mousemove="handleMouseMove"
          @mouseup="handleMouseUp"
          @mouseleave="handleMouseUp"
        >
          <img :src="card.imageUrl" :alt="card.name" @error="handleImageError">
        </div>
      </div>
      <div class="controls" :class="{ 'bottom': isPackOpen }">
        <button v-if="!isPackOpen" @click="openNewPack" class="open-pack-button" :disabled="loading">Open New Pack</button>
        <button v-if="isPackOpen" @click="resetPack" class="reset-button">Reset Pack</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { openDB } from 'idb';

const DB_NAME = 'CardSearchDB';
const STORE_NAME = 'cards';
const CACHE_VERSION = 5;

export default {
  name: 'PackSimulator',
  setup() {
    const packImage = ref(require('@/assets/TWM-Pack.jpg'));
    const isPackOpen = ref(false);
    const cards = ref([]);
    const currentCardIndex = ref(0);
    const allCards = ref([]);
    const loading = ref(true);
    const error = ref(null);
    const db = ref(null);
    const isMouseDown = ref(false);
    const startX = ref(0);

    const initIndexedDB = async () => {
      try {
        db.value = await openDB(DB_NAME, CACHE_VERSION, {
          upgrade(db) {
            if (!db.objectStoreNames.contains(STORE_NAME)) {
              db.createObjectStore(STORE_NAME, { keyPath: 'path' });
            }
          },
        });
        console.log('IndexedDB initialized successfully');
      } catch (error) {
        console.error('Error initializing IndexedDB:', error);
        error.value = "Failed to initialize card database. Please try again later.";
      }
    };

    const loadCardsFromIndexedDB = async () => {
      try {
        const tx = db.value.transaction(STORE_NAME, 'readonly');
        const store = tx.objectStore(STORE_NAME);
        allCards.value = await store.getAll();
        allCards.value = allCards.value.filter(card => card.path.includes('Scarlet_&_Violet/TWM/'));
        
        allCards.value = allCards.value.map(card => ({
          name: card.name,
          rarity: card.rarity || inferRarity(card),
          imageUrl: card.imageUrl,
          cardNumber: card.cardNumber,
          setName: card.setName,
        }));

        console.log(`Loaded ${allCards.value.length} TWM cards from IndexedDB`);
        console.log('Sample card:', allCards.value[0]);
        console.log('Rarities distribution:', getRaritiesDistribution());
        loading.value = false;
      } catch (error) {
        console.error('Error loading cards from IndexedDB:', error);
        error.value = "Failed to load cards. Please try again later.";
        loading.value = false;
      }
    };

    const inferRarity = (card) => {
      if (card.name.includes('EX')) return 'Ultra Rare';
      if (card.name.includes('V')) return 'Ultra Rare';
      if (parseInt(card.cardNumber) > 200) return 'Rare';
      if (parseInt(card.cardNumber) > 100) return 'Uncommon';
      return 'Common';
    };

    const getRaritiesDistribution = () => {
      const distribution = {};
      allCards.value.forEach(card => {
        distribution[card.rarity] = (distribution[card.rarity] || 0) + 1;
      });
      return distribution;
    };

    const getRandomCards = (rarity, count) => {
      const rarityCards = allCards.value.filter(card => card.rarity === rarity);
      if (rarityCards.length < count) {
        console.warn(`Not enough ${rarity} cards. Required: ${count}, Available: ${rarityCards.length}`);
        return rarityCards;
      }
      return rarityCards.sort(() => 0.5 - Math.random()).slice(0, count);
    };

    const openNewPack = () => {
      if (loading.value || allCards.value.length === 0) {
        console.error('Cards not loaded yet');
        return;
      }

      const packComposition = [
        { rarity: 'Common', count: 4 },
        { rarity: 'Uncommon', count: 3 },
        { rarity: 'Rare', count: 1 },
      ];

      const specialRarityRoll = Math.random();
      if (specialRarityRoll < 0.1376) {
        packComposition.push({ rarity: 'Double Rare', count: 1 });
      } else if (specialRarityRoll < 0.2033) {
        packComposition.push({ rarity: 'Ultra Rare', count: 1 });
      } else if (specialRarityRoll < 0.28) {
        packComposition.push({ rarity: 'Illustration Rare', count: 1 });
      } else if (specialRarityRoll < 0.3115) {
        packComposition.push({ rarity: 'Special Illustration Rare', count: 1 });
      } else if (specialRarityRoll < 0.33) {
        packComposition.push({ rarity: 'Hyper Rare', count: 1 });
      } else {
        packComposition[2].count++;
      }

      const newCards = packComposition.flatMap(({ rarity, count }) => {
        const selected = getRandomCards(rarity, count);
        if (selected.length < count) {
          const remaining = count - selected.length;
          console.warn(`Filling ${remaining} ${rarity} cards with random cards`);
          const fillerCards = getRandomCards('Common', remaining);
          return [...selected, ...fillerCards];
        }
        return selected;
      });

      cards.value = newCards.sort(() => Math.random() - 0.5);
      isPackOpen.value = true;
      currentCardIndex.value = 0;

      console.log('New pack opened:', cards.value);
    };

    const resetPack = () => {
      isPackOpen.value = false;
      currentCardIndex.value = 0;
      cards.value = [];
    };

    const handleCardTouchStart = (event) => {
      startX.value = event.touches[0].clientX;
    };

    const handleCardTouchMove = (event) => {
      event.preventDefault();
    };

    const handleCardTouchEnd = (event) => {
      const endX = event.changedTouches[0].clientX;
      handleSwipe(startX.value - endX);
    };

    const handleMouseDown = (event) => {
      isMouseDown.value = true;
      startX.value = event.clientX;
    };

    const handleMouseMove = (event) => {
      if (isMouseDown.value) {
        event.preventDefault();
      }
    };

    const handleMouseUp = (event) => {
      if (isMouseDown.value) {
        isMouseDown.value = false;
        handleSwipe(startX.value - event.clientX);
      }
    };

    const handleSwipe = (swipeDistance) => {
      const swipeThreshold = 50;
      if (Math.abs(swipeDistance) > swipeThreshold) {
        if (swipeDistance > 0) {
          showNextCard();
        } else {
          showPreviousCard();
        }
      }
    };

    const showPreviousCard = () => {
      if (currentCardIndex.value < cards.value.length - 1) {
        currentCardIndex.value++;
      }
    };

    const showNextCard = () => {
      if (currentCardIndex.value > 0) {
        currentCardIndex.value--;
      }
    };

    const handleImageError = (event) => {
      console.error('Image failed to load:', event.target.src);
      event.target.src = require('@/assets/placeholder.webp');
    };

    onMounted(async () => {
      await initIndexedDB();
      if (db.value) {
        await loadCardsFromIndexedDB();
      }
    });

    return {
      packImage,
      isPackOpen,
      cards,
      currentCardIndex,
      loading,
      error,
      openNewPack,
      resetPack,
      handleCardTouchStart,
      handleCardTouchMove,
      handleCardTouchEnd,
      handleMouseDown,
      handleMouseMove,
      handleMouseUp,
      handleImageError,
    };
  },
};
</script>

<style scoped>
.pack-simulator {
  font-family: Arial, sans-serif;
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.simulator-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pack-container {
  width: 200px;
  height: 360px;
  margin-bottom: 20px;
  cursor: pointer;
}

.pack-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.cards-container {
  position: relative;
  width: 100%;
  max-width: 300px;
  height: 420px;
  margin-bottom: 20px;
}

.card {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  touch-action: none;
  user-select: none;
}

.card.visible {
  opacity: 1;
}

.card img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.controls {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.controls.bottom {
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
}

.open-pack-button,
.reset-button {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.open-pack-button {
  background-color: #4CAF50;
}

.reset-button {
  background-color: #f44336;
}

.open-pack-button:disabled,
.reset-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.loading, .error {
  text-align: center;
  margin: 20px 0;
  font-size: 18px;
}

.error {
  color: red;
}

@media (max-width: 480px) {
  .pack-container {
    width: 150px;
    height: 270px;
  }

  .cards-container {
    height: 350px;
  }

  .open-pack-button,
  .reset-button {
    font-size: 14px;
    padding: 8px 16px;
  }
}
</style>