<template>
  <div class="avatar-generator">
    <div class="avatar-preview">
      <canvas ref="avatarCanvas" width="240" height="240"></canvas>
    </div>
    <div class="avatar-options">
      <div v-for="feature in features" :key="feature" class="feature-group">
        <button @click="changeFeature(feature, -1)">←</button>
        <span>{{ featureNames[feature] }}</span>
        <button @click="changeFeature(feature, 1)">→</button>
        <select v-if="colorOptions[feature]" v-model="selectedColors[feature]" @change="updateFeatureColor(feature)">
          <option v-for="color in colorOptions[feature]" :key="color" :value="color">{{ color }}</option>
        </select>
      </div>
    </div>
    <div class="button-group">
      <button @click="randomizeAvatar" class="randomize-button">Randomize Avatar</button>
      <button @click="goToPreviousRandomized" class="previous-button" :disabled="!hasPreviousRandomized">
        Previous Randomized
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AvatarGenerator',
  props: {
    initialAvatarData: {
      type: Object,
      default: null
    },
    currentIndexes: {
      type: Object,
      default: () => ({})
    },
    selectedColors: {
      type: Object,
      default: () => ({
        Clothes: 'Black',
        Eyebrows: 'Black',
        Eyes: 'Black',
        Hair: 'Black'
      })
    }
  },
  data() {
    return {
      features: ['BaseSkin', 'Clothes', 'Eyebrows', 'Eyes', 'Hair', 'MouthsDefault'],
      featureNames: {
        BaseSkin: 'Skin Tone',
        Clothes: 'Clothes',
        Eyebrows: 'Eyebrows',
        Eyes: 'Eyes',
        Hair: 'Hair',
        MouthsDefault: 'Mouth'
      },
      featureCounts: {},
      spriteSources: {},
      colorOptions: {
        Clothes: ['Black', 'Blonde', 'Blue', 'Blue Green', 'Brown', 'Dark Blue', 'Dark Brown', 'Green', 'Light Brown', 'Light Green', 'Lilac', 'Magenta', 'Orange', 'Pink', 'Red', 'White'],
        Eyebrows: ['Black', 'Blonde', 'Blue', 'Blue Green', 'Brown', 'Dark Blue', 'Dark Brown', 'Green', 'Light Brown', 'Light Green', 'Lilac', 'Magenta', 'Orange', 'Pink', 'Red', 'White'],
        Eyes: ['Black', 'Blonde', 'Blue', 'Blue Green', 'Brown', 'Dark Blue', 'Dark Brown', 'Green', 'Light Brown', 'Light Green', 'Lilac', 'Magenta', 'Orange', 'Pink', 'Red', 'White'],
        Hair: ['Black', 'Blonde', 'Blue', 'Blue Green', 'Brown', 'Dark Blue', 'Dark Brown', 'Green', 'Light Brown', 'Light Green', 'Lilac', 'Magenta', 'Orange', 'Pink', 'Red', 'White']
      },
      scale: 4,
      previousRandomized: null,
      hasPreviousRandomized: false,
      featureFileNames: {},
    };
  },
  watch: {
    currentIndexes: {
      handler() {
        this.loadSprites();
        this.drawAvatar();
      },
      deep: true
    },
    selectedColors: {
      handler() {
        this.loadSprites();
        this.drawAvatar();
      },
      deep: true
    }
  },
  mounted() {
    this.initializeDefaultAvatar();
    this.loadSprites();
    this.setupCanvas();
    if (this.initialAvatarData) {
      this.$nextTick(() => {
        this.drawAvatar();
      });
    }
  },
  methods: {
    setupCanvas() {
      const canvas = this.$refs.avatarCanvas;
      const ctx = canvas.getContext('2d');
      ctx.imageSmoothingEnabled = false; // Disable anti-aliasing
    },
    initializeDefaultAvatar() {
      const context = require.context('@/assets/avatar/', true, /\.png$/);
      
      this.features.forEach(feature => {
        let featureImages;
        if (feature === 'BaseSkin' || feature === 'MouthsDefault') {
          featureImages = context.keys()
            .filter(key => key.startsWith(`./${feature}/`))
            .map(key => ({ path: key, src: context(key) }));
        } else {
          const defaultColor = this.selectedColors[feature];
          featureImages = context.keys()
            .filter(key => key.startsWith(`./${feature}/${defaultColor}/`))
            .map(key => ({ path: key, src: context(key) }));
        }
        
        this.spriteSources[feature] = featureImages.map(img => img.src);
        this.featureCounts[feature] = featureImages.length;
        if (!this.currentIndexes[feature]) {
          this.$emit('feature-changed', feature, 0);
        }
        // Initialize featureFileNames
        this.featureFileNames[feature] = this.getFileName(featureImages[0].path);
      });
      
      this.drawAvatar();
    },
    loadSprites() {
      const context = require.context('@/assets/avatar/', true, /\.png$/);
      this.features.forEach(feature => {
        let featureImages;
        if (feature === 'BaseSkin' || feature === 'MouthsDefault') {
          featureImages = context.keys()
            .filter(key => key.startsWith(`./${feature}/`))
            .map(key => ({ path: key, src: context(key) }));
        } else {
          const color = this.selectedColors[feature];
          featureImages = context.keys()
            .filter(key => key.startsWith(`./${feature}/${color}/`))
            .map(key => ({ path: key, src: context(key) }));
        }
        
        this.spriteSources[feature] = featureImages.map(img => img.src);
        this.featureCounts[feature] = featureImages.length;
        // Ensure currentIndexes doesn't exceed the new featureCounts
        if (this.currentIndexes[feature] >= this.featureCounts[feature]) {
          this.$emit('feature-changed', feature, this.featureCounts[feature] - 1);
        }
        // Update featureFileNames
        this.featureFileNames[feature] = this.getFileName(featureImages[this.currentIndexes[feature]].path);
      });
      this.drawAvatar();
    },
    randomizeAvatar() {
      // Store current state before randomizing
      this.previousRandomized = {
        currentIndexes: { ...this.currentIndexes },
        selectedColors: { ...this.selectedColors },
        featureFileNames: { ...this.featureFileNames },
      };
      this.hasPreviousRandomized = true;

      const context = require.context('@/assets/avatar/', true, /\.png$/);
      
      this.features.forEach(feature => {
        if (feature === 'MouthsDefault' || feature === 'BaseSkin') {
          const featureImages = context.keys()
            .filter(key => key.startsWith(`./${feature}/`))
            .map(key => ({ path: key, src: context(key) }));
          const randomIndex = Math.floor(Math.random() * featureImages.length);
          this.$emit('feature-changed', feature, randomIndex);
          this.featureFileNames[feature] = this.getFileName(featureImages[randomIndex].path);
        } else {
          const randomColorIndex = Math.floor(Math.random() * this.colorOptions[feature].length);
          const randomColor = this.colorOptions[feature][randomColorIndex];
          this.$emit('color-changed', feature, randomColor);
          
          const featureImages = context.keys()
            .filter(key => key.startsWith(`./${feature}/${randomColor}/`))
            .map(key => ({ path: key, src: context(key) }));
          const randomImageIndex = Math.floor(Math.random() * featureImages.length);
          this.$emit('feature-changed', feature, randomImageIndex);
          this.featureFileNames[feature] = this.getFileName(featureImages[randomImageIndex].path);
        }
      });

      this.loadSprites();
    },
    goToPreviousRandomized() {
      if (this.hasPreviousRandomized && this.previousRandomized) {
        Object.entries(this.previousRandomized.currentIndexes).forEach(([feature, index]) => {
          this.$emit('feature-changed', feature, index);
        });
        Object.entries(this.previousRandomized.selectedColors).forEach(([feature, color]) => {
          this.$emit('color-changed', feature, color);
        });
        this.featureFileNames = { ...this.previousRandomized.featureFileNames };
        this.loadSprites();
        this.hasPreviousRandomized = false;
      }
    },
    changeFeature(feature, direction) {
      const count = this.featureCounts[feature];
      const newIndex = (this.currentIndexes[feature] + direction + count) % count;
      this.$emit('feature-changed', feature, newIndex);
      
      // Update featureFileNames
      const context = require.context('@/assets/avatar/', true, /\.png$/);
      let featureImages;
      if (feature === 'BaseSkin' || feature === 'MouthsDefault') {
        featureImages = context.keys().filter(key => key.startsWith(`./${feature}/`));
      } else {
        const color = this.selectedColors[feature];
        featureImages = context.keys().filter(key => key.startsWith(`./${feature}/${color}/`));
      }
      this.featureFileNames[feature] = this.getFileName(featureImages[newIndex]);
      
      this.drawAvatar();
    },
    updateFeatureColor(feature) {
      this.$emit('color-changed', feature, this.selectedColors[feature]);
      this.loadSprites();
      this.drawAvatar();
    },
    drawAvatar() {
      const canvas = this.$refs.avatarCanvas;
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      const drawLayer = (feature) => {
        return new Promise((resolve) => {
          const img = new Image();
          img.src = this.spriteSources[feature][this.currentIndexes[feature]];
          img.onload = () => {
            ctx.drawImage(
              img,
              0, 0, 60, 60, // Source dimensions
              0, 0, 60 * this.scale, 60 * this.scale // Destination dimensions
            );
            resolve();
          };
        });
      };

      // Draw layers in order
      this.features.reduce((promise, feature) => {
        return promise.then(() => drawLayer(feature));
      }, Promise.resolve());

      this.$nextTick(() => {
        this.$emit('avatar-generated', this.getAvatarData());
      });
    },
    getFileName(path) {
      return path.split('/').pop();
    },
    getAvatarData() {
      return {
        imageData: this.$refs.avatarCanvas.toDataURL(),
        features: { ...this.featureFileNames },
        colors: { ...this.selectedColors }
      };
    }
  }
};
</script>

<style scoped>
.avatar-generator {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}

.avatar-preview {
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
}

.avatar-preview canvas {
  width: 100%;
  height: auto;
}

.avatar-options {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 255px;
}

.feature-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.feature-group span {
  flex-grow: 1;
  text-align: center;
  font-size: 13px;
}

.feature-group button {
  padding: 5px 10px;
  font-size: 14px;
}

.feature-group select {
  padding: 5px;
  font-size: 14px;
  margin-left: 7.5px;
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.randomize-button,
.previous-button {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.randomize-button {
  background-color: #4CAF50;
}

.randomize-button:hover {
  background-color: #45a049;
}

.previous-button {
  background-color: #3498db;
}

.previous-button:hover {
  background-color: #2980b9;
}

.previous-button:disabled {
  background-color: #bdc3c7;
  cursor: not-allowed;
}

@media (max-width: 480px) {
  .avatar-generator {
    max-width: 250px;
  }
}
</style>