<template>
  <div id="app">
    <SessionWarningDialog />
    <transition name="fade" mode="out-in">
      <AuthComponent v-if="!user" @sign-in="handleSignIn" />
      <MainMenu 
        v-else-if="currentView === 'main-menu'" 
        @create-deck="createNewDeck" 
        @edit-deck="editDeck" 
        @navigate="navigateTo"
      />
      <MobileDeckView 
        v-else-if="currentView === 'deck-view'" 
        :deck="currentDeck" 
        @save-deck="saveDeck" 
        @delete-deck="deleteDeck"
        @cancel="goToMainMenu" 
        @go-back="goToMainMenu"
      />
      <BattleField
        v-else-if="currentView === 'battlefield'"
        @go-back="goToMainMenu"
      />
      <AdminPanel
        v-else-if="currentView === 'admin'"
        @go-back="goToMainMenu"
      />
      <Profile
        v-else-if="currentView === 'profile'"
        @go-back="goToMainMenu"
      />
      <PackSimulator
        v-else-if="currentView === 'pack-simulator'"
        @go-back="goToMainMenu"
      />
      <ForumPage
        v-else-if="currentView === 'forum'"
        @go-back="goToMainMenu"
      />
    </transition>
  </div>
</template>

<script>
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { db } from './firebase';
import { getFirestore, doc, setDoc, addDoc, collection, deleteDoc } from 'firebase/firestore';
import AuthComponent from './components/AuthComponent.vue';
import MainMenu from './components/MainMenu.vue';
import MobileDeckView from './components/MobileDeckView.vue';
import BattleField from './components/BattleField.vue';
import PackSimulator from './components/PackSimulator.vue';
import AdminPanel from './components/AdminPanel.vue';
import Profile from './components/Profile.vue';
import ForumPage from './components/ForumPage.vue';
import SessionWarningDialog from '@/components/SessionWarningDialog.vue';
import { setupSessionTimeout, clearSessionTimeout, startSessionTimeout } from './utils/sessionTimeout';

export default {
  name: 'App',
  components: {
    AuthComponent,
    MainMenu,
    MobileDeckView,
    BattleField,
    PackSimulator,
    AdminPanel,
    Profile,
    ForumPage,
    SessionWarningDialog
  },
  data() {
    return {
      user: null,
      currentView: 'main-menu',
      currentDeck: null
    };
  },
  methods: {
    handleSignIn() {
      this.currentView = 'main-menu';
    },
    createNewDeck() {
      this.currentDeck = { name: 'New Inventory', cards: [], deckValue: 0 };
      this.currentView = 'deck-view';
    },
    editDeck(deck) {
      this.currentDeck = deck;
      this.currentView = 'deck-view';
    },
    async saveDeck(updatedDeck) {
      try {
        const auth = getAuth();
        const userDecksRef = collection(db, 'users', auth.currentUser.uid, 'decks');
        if (updatedDeck.id) {
          await setDoc(doc(userDecksRef, updatedDeck.id), updatedDeck, { merge: true });
        } else {
          const docRef = await addDoc(userDecksRef, updatedDeck);
          updatedDeck.id = docRef.id;
        }
        this.currentView = 'main-menu';
      } catch (error) {
        console.error('Error saving deck:', error);
        // Handle error (show message to user)
      }
    },
    async deleteDeck(deckId) {
      try {
        const auth = getAuth();
        await deleteDoc(doc(db, 'users', auth.currentUser.uid, 'decks', deckId));
        this.currentView = 'main-menu';
      } catch (error) {
        console.error('Error deleting deck:', error);
        // Handle error (show message to user)
      }
    },
    navigateTo(page) {
      this.currentView = page;
    },
    goToMainMenu() {
      this.currentView = 'main-menu';
    },
    async updateOnlineStatus(status) {
      const auth = getAuth();
      const db = getFirestore();
      const user = auth.currentUser;
      if (user) {
        const userRef = doc(db, 'users', user.uid);
        await setDoc(userRef, {
          online: status,
          lastActive: new Date()
        }, { merge: true });
      }
    },
    setupAuthListener() {
      const auth = getAuth();
      onAuthStateChanged(auth, (currentUser) => {
        this.user = currentUser;
        if (currentUser) {
          this.currentView = 'main-menu';
          setupSessionTimeout();
          // Start the session timeout after a short delay to avoid immediate warning
          setTimeout(startSessionTimeout, 1000);
        } else {
          clearSessionTimeout();
        }
      });
    }
  },
  watch: {
    user(newUser) {
      if (newUser) {
        setupSessionTimeout();
        // Start the session timeout after a short delay to avoid immediate warning
        setTimeout(startSessionTimeout, 1000);
      } else {
        clearSessionTimeout();
      }
    }
  },
  mounted() {
    this.setupAuthListener();
    this.updateOnlineStatus(true);
    window.addEventListener('beforeunload', () => this.updateOnlineStatus(false));
  },
  beforeDestroy() {
    this.updateOnlineStatus(false);
    window.removeEventListener('beforeunload', () => this.updateOnlineStatus(false));
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>