import Vue from 'vue'
import VueRouter from 'vue-router'
//import store from '../store'

// Import components
import Home from '../views/Home.vue'
import MainMenu from '../components/MainMenu.vue'
import MobileDeckView from '../components/MobileDeckView.vue'
import AdminPanel from '@/components/AdminPanel.vue'
import PackSimulator from '../components/PackSimulator.vue'
import Profile from '@/components/Profile.vue'
import ForumPage from '@/components/ForumPage.vue'
import { getAuth, onAuthStateChanged } from 'firebase/auth';
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/main-menu',
    name: 'MainMenu',
    component: MainMenu,
    meta: { requiresAuth: true }
  },
  {
    path: '/deck-view',
    name: 'DeckView',
    component: MobileDeckView,
    meta: { requiresAuth: true }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: AdminPanel,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/pack-simulator',
    name: 'PackSimulator',
    component: PackSimulator,
    meta: { requiresAuth: true }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: { requiresAuth: true }
  },
  {
    path: '/forum',
    name: 'Forum',
    component: ForumPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  // Add other routes as needed (settings, support, etc.)
  {
    path: '/account',
    name: 'Account',
    component: () => import(/* webpackChunkName: "account" */ '../views/Account.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/support',
    name: 'Support',
    component: () => import(/* webpackChunkName: "support" */ '../views/Support.vue')
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import(/* webpackChunkName: "terms" */ '../views/Terms.vue')
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue')
  },
  {
    path: '/news',
    name: 'News',
    component: () => import(/* webpackChunkName: "news" */ '../views/News.vue')
  },
  {
    path: '/inbox',
    name: 'Inbox',
    component: () => import(/* webpackChunkName: "inbox" */ '../views/Inbox.vue'),
    meta: { requiresAuth: true }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const auth = getAuth();
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin);

  onAuthStateChanged(auth, (user) => {
    if (requiresAuth && !user) {
      next('/');
    } else if (requiresAdmin && (!user || !user.isAdmin)) {
      next('/main-menu');
    } else {
      next();
    }
  });
});

export default router