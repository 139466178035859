<template>
    <div class="home">
      <h1>Welcome to iMonster</h1>
      <p>Your Pokémon card collection starts here.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Home'
  }
  </script>
  
  <style scoped>
  .home {
    padding: 20px;
  }
  </style>